export const STATE_FOOTER_CITIES = {
	AL: {
		cities: [
			{ name: 'Alabaster', slug: 'alabaster-al' },
			{ name: 'Albertville', slug: 'albertville-al' },
			{ name: 'Alexander City', slug: 'alexander-city-al' },
			{ name: 'Anniston', slug: 'anniston-al' },
			{ name: 'Arab', slug: 'arab-al' },
			{ name: 'Athens', slug: 'athens-al' },
			{ name: 'Auburn', slug: 'auburn-al' },
			{ name: 'Bessemer', slug: 'bessemer-al' },
			{ name: 'Birmingham', slug: 'birmingham-al' },
			{ name: 'Calera', slug: 'calera-al' },
			{ name: 'Chelsea', slug: 'chelsea-al' },
			{ name: 'Clanton', slug: 'clanton-al' },
			{ name: 'Cullman', slug: 'cullman-al' },
			{ name: 'Daphne', slug: 'daphne-al' },
			{ name: 'Decatur', slug: 'decatur-al' },
			{ name: 'Dothan', slug: 'dothan-al' },
			{ name: 'Enterprise', slug: 'enterprise-al' },
			{ name: 'Fairhope', slug: 'fairhope-al' },
			{ name: 'Florence', slug: 'florence-al' },
			{ name: 'Foley', slug: 'foley-al' },
			{ name: 'Fort Payne', slug: 'fort-payne-al' },
			{ name: 'Gadsden', slug: 'gadsden-al' },
			{ name: 'Gardendale', slug: 'gardendale-al' },
			{ name: 'Gulf Shores', slug: 'gulf-shores-al' },
			{ name: 'Guntersville', slug: 'guntersville-al' },
			{ name: 'Hartselle', slug: 'hartselle-al' },
			{ name: 'Helena', slug: 'helena-al' },
			{ name: 'Hoover', slug: 'hoover-al' },
			{ name: 'Houston', slug: 'houston-al' },
			{ name: 'Huntsville', slug: 'huntsville-al' },
			{ name: 'Jacksonville', slug: 'jacksonville-al' },
			{ name: 'Jasper', slug: 'jasper-al' },
			{ name: 'Jefferson', slug: 'jefferson-al' },
			{ name: 'Leeds', slug: 'leeds-al' },
			{ name: 'Madison', slug: 'madison-al' },
			{ name: 'Millbrook', slug: 'millbrook-al' },
			{ name: 'Mobile', slug: 'mobile-al' },
			{ name: 'Montgomery', slug: 'montgomery-al' },
			{ name: 'Mountain Brook', slug: 'mountain-brook-al' },
			{ name: 'Muscle Shoals', slug: 'muscle-shoals-al' },
			{ name: 'Northport', slug: 'northport-al' },
			{ name: 'Opelika', slug: 'opelika-al' },
			{ name: 'Orange Beach', slug: 'orange-beach-al' },
			{ name: 'Oxford', slug: 'oxford-al' },
			{ name: 'Ozark', slug: 'ozark-al' },
			{ name: 'Pelham', slug: 'pelham-al' },
			{ name: 'Pell City', slug: 'pell-city-al' },
			{ name: 'Phenix City', slug: 'phenix-city-al' },
			{ name: 'Prattville', slug: 'prattville-al' },
			{ name: 'Saraland', slug: 'saraland-al' },
			{ name: 'Scottsboro', slug: 'scottsboro-al' },
			{ name: 'Shelby', slug: 'shelby-al' },
			{ name: 'Sylacauga', slug: 'sylacauga-al' },
			{ name: 'Troy', slug: 'troy-al' },
			{ name: 'Trussville', slug: 'trussville-al' },
			{ name: 'Tuscaloosa', slug: 'tuscaloosa-al' },
			{ name: 'Valley', slug: 'valley-al' },
			{ name: 'Wetumpka', slug: 'wetumpka-al' },
		],
		stateName: 'Alabama',
	},
	AK: {
		cities: [
			{ name: 'Akutan', slug: 'akutan-ak' },
			{ name: 'Alakanuk', slug: 'alakanuk-ak' },
			{ name: 'Anchorage', slug: 'anchorage-ak' },
			{ name: 'Aniak', slug: 'aniak-ak' },
			{ name: 'Bethel', slug: 'bethel-ak' },
			{ name: 'Chevak', slug: 'chevak-ak' },
			{ name: 'Cordova', slug: 'cordova-ak' },
			{ name: 'Craig', slug: 'craig-ak' },
			{ name: 'Delta Junction', slug: 'delta-junction-ak' },
			{ name: 'Dillingham', slug: 'dillingham-ak' },
			{ name: 'Emmonak', slug: 'emmonak-ak' },
			{ name: 'Fairbanks', slug: 'fairbanks-ak' },
			{ name: 'Fort Yukon', slug: 'fort-yukon-ak' },
			{ name: 'Galena', slug: 'galena-ak' },
			{ name: 'Gambell', slug: 'gambell-ak' },
			{ name: 'Haines', slug: 'haines-ak' },
			{ name: 'Homer', slug: 'homer-ak' },
			{ name: 'Hoonah', slug: 'hoonah-ak' },
			{ name: 'Hooper Bay', slug: 'hooper-bay-ak' },
			{ name: 'Houston', slug: 'houston-ak' },
			{ name: 'Juneau', slug: 'juneau-ak' },
			{ name: 'Kake', slug: 'kake-ak' },
			{ name: 'Kenai', slug: 'kenai-ak' },
			{ name: 'Ketchikan', slug: 'ketchikan-ak' },
			{ name: 'King Cove', slug: 'king-cove-ak' },
			{ name: 'Klawock', slug: 'klawock-ak' },
			{ name: 'Kodiak', slug: 'kodiak-ak' },
			{ name: 'Kotlik', slug: 'kotlik-ak' },
			{ name: 'Kotzebue', slug: 'kotzebue-ak' },
			{ name: 'Kwethluk', slug: 'kwethluk-ak' },
			{ name: 'Mountain Village', slug: 'mountain-village-ak' },
			{ name: 'New Stuyahok', slug: 'new-stuyahok-ak' },
			{ name: 'Nome', slug: 'nome-ak' },
			{ name: 'Noorvik', slug: 'noorvik-ak' },
			{ name: 'North Pole', slug: 'north-pole-ak' },
			{ name: 'Nunapitchuk', slug: 'nunapitchuk-ak' },
			{ name: 'Palmer', slug: 'palmer-ak' },
			{ name: 'Petersburg', slug: 'petersburg-ak' },
			{ name: 'Pilot Station', slug: 'pilot-station-ak' },
			{ name: 'Point Hope', slug: 'point-hope-ak' },
			{ name: 'Quinhagak', slug: 'quinhagak-ak' },
			{ name: 'Savoonga', slug: 'savoonga-ak' },
			{ name: 'Scammon Bay', slug: 'scammon-bay-ak' },
			{ name: 'Selawik', slug: 'selawik-ak' },
			{ name: 'Seward', slug: 'seward-ak' },
			{ name: 'Shishmaref', slug: 'shishmaref-ak' },
			{ name: 'Sitka', slug: 'sitka-ak' },
			{ name: 'Skagway', slug: 'skagway-ak' },
			{ name: 'Soldotna', slug: 'soldotna-ak' },
			{ name: 'Stebbins', slug: 'stebbins-ak' },
			{ name: 'Togiak', slug: 'togiak-ak' },
			{ name: 'Toksook Bay', slug: 'toksook-bay-ak' },
			{ name: 'Unalakleet', slug: 'unalakleet-ak' },
			{ name: 'Unalaska', slug: 'unalaska-ak' },
			{ name: 'Valdez', slug: 'valdez-ak' },
			{ name: 'Wainwright', slug: 'wainwright-ak' },
			{ name: 'Wasilla', slug: 'wasilla-ak' },
			{ name: 'Wrangell', slug: 'wrangell-ak' },
		],
		stateName: 'Alaska',
	},
	AZ: {
		cities: [
			{ name: 'Apache Junction', slug: 'apache-junction-az' },
			{ name: 'Avondale', slug: 'avondale-az' },
			{ name: 'Bisbee', slug: 'bisbee-az' },
			{ name: 'Buckeye', slug: 'buckeye-az' },
			{ name: 'Bullhead City', slug: 'bullhead-city-az' },
			{ name: 'Camp Verde', slug: 'camp-verde-az' },
			{ name: 'Casa Grande', slug: 'casa-grande-az' },
			{ name: 'Cave Creek', slug: 'cave-creek-az' },
			{ name: 'Chandler', slug: 'chandler-az' },
			{ name: 'Chino Valley', slug: 'chino-valley-az' },
			{ name: 'Cochise', slug: 'cochise-az' },
			{ name: 'Colorado City', slug: 'colorado-city-az' },
			{ name: 'Coolidge', slug: 'coolidge-az' },
			{ name: 'Cottonwood', slug: 'cottonwood-az' },
			{ name: 'Douglas', slug: 'douglas-az' },
			{ name: 'Eagar', slug: 'eagar-az' },
			{ name: 'El Mirage', slug: 'el-mirage-az' },
			{ name: 'Eloy', slug: 'eloy-az' },
			{ name: 'Flagstaff', slug: 'flagstaff-az' },
			{ name: 'Florence', slug: 'florence-az' },
			{ name: 'Fountain Hills', slug: 'fountain-hills-az' },
			{ name: 'Gilbert', slug: 'gilbert-az' },
			{ name: 'Glendale', slug: 'glendale-az' },
			{ name: 'Globe', slug: 'globe-az' },
			{ name: 'Goodyear', slug: 'goodyear-az' },
			{ name: 'Kingman', slug: 'kingman-az' },
			{ name: 'Lake Havasu City', slug: 'lake-havasu-city-az' },
			{ name: 'Litchfield Park', slug: 'litchfield-park-az' },
			{ name: 'Marana', slug: 'marana-az' },
			{ name: 'Maricopa', slug: 'maricopa-az' },
			{ name: 'Mesa', slug: 'mesa-az' },
			{ name: 'Nogales', slug: 'nogales-az' },
			{ name: 'Page', slug: 'page-az' },
			{ name: 'Paradise Valley', slug: 'paradise-valley-az' },
			{ name: 'Payson', slug: 'payson-az' },
			{ name: 'Peoria', slug: 'peoria-az' },
			{ name: 'Phoenix', slug: 'phoenix-az' },
			{ name: 'Prescott Valley', slug: 'prescott-valley-az' },
			{ name: 'Prescott', slug: 'prescott-az' },
			{ name: 'Queen Creek', slug: 'queen-creek-az' },
			{ name: 'Safford', slug: 'safford-az' },
			{ name: 'Sahuarita', slug: 'sahuarita-az' },
			{ name: 'San Luis', slug: 'san-luis-az' },
			{ name: 'Scottsdale', slug: 'scottsdale-az' },
			{ name: 'Sedona', slug: 'sedona-az' },
			{ name: 'Show Low', slug: 'show-low-az' },
			{ name: 'Sierra Vista', slug: 'sierra-vista-az' },
			{ name: 'Snowflake', slug: 'snowflake-az' },
			{ name: 'Somerton', slug: 'somerton-az' },
			{ name: 'Sun City West', slug: 'sun-city-west-az' },
			{ name: 'Sun City', slug: 'sun-city-az' },
			{ name: 'Surprise', slug: 'surprise-az' },
			{ name: 'Tempe', slug: 'tempe-az' },
			{ name: 'Tolleson', slug: 'tolleson-az' },
			{ name: 'Tucson', slug: 'tucson-az' },
			{ name: 'Winslow', slug: 'winslow-az' },
			{ name: 'Youngtown', slug: 'youngtown-az' },
			{ name: 'Yuma', slug: 'yuma-az' },
		],
		stateName: 'Arizona',
	},
	AR: {
		cities: [
			{ name: 'Arkadelphia', slug: 'arkadelphia-ar' },
			{ name: 'Batesville', slug: 'batesville-ar' },
			{ name: 'Beebe', slug: 'beebe-ar' },
			{ name: 'Bella Vista', slug: 'bella-vista-ar' },
			{ name: 'Benton', slug: 'benton-ar' },
			{ name: 'Bentonville', slug: 'bentonville-ar' },
			{ name: 'Blytheville', slug: 'blytheville-ar' },
			{ name: 'Bryant', slug: 'bryant-ar' },
			{ name: 'Cabot', slug: 'cabot-ar' },
			{ name: 'Camden', slug: 'camden-ar' },
			{ name: 'Centerton', slug: 'centerton-ar' },
			{ name: 'Clarksville', slug: 'clarksville-ar' },
			{ name: 'Conway', slug: 'conway-ar' },
			{ name: 'Crossett', slug: 'crossett-ar' },
			{ name: 'De Queen', slug: 'de-queen-ar' },
			{ name: 'El Dorado', slug: 'el-dorado-ar' },
			{ name: 'Farmington', slug: 'farmington-ar' },
			{ name: 'Fayetteville', slug: 'fayetteville-ar' },
			{ name: 'Forrest City', slug: 'forrest-city-ar' },
			{ name: 'Fort Smith', slug: 'fort-smith-ar' },
			{ name: 'Greenbrier', slug: 'greenbrier-ar' },
			{ name: 'Greenwood', slug: 'greenwood-ar' },
			{ name: 'Harrison', slug: 'harrison-ar' },
			{ name: 'Heber Springs', slug: 'heber-springs-ar' },
			{ name: 'Hope', slug: 'hope-ar' },
			{ name: 'Hot Springs', slug: 'hot-springs-ar' },
			{ name: 'Jacksonville', slug: 'jacksonville-ar' },
			{ name: 'Jefferson', slug: 'jefferson-ar' },
			{ name: 'Jonesboro', slug: 'jonesboro-ar' },
			{ name: 'Little Rock', slug: 'little-rock-ar' },
			{ name: 'Lowell', slug: 'lowell-ar' },
			{ name: 'Magnolia', slug: 'magnolia-ar' },
			{ name: 'Malvern', slug: 'malvern-ar' },
			{ name: 'Marion', slug: 'marion-ar' },
			{ name: 'Maumelle', slug: 'maumelle-ar' },
			{ name: 'Monticello', slug: 'monticello-ar' },
			{ name: 'Morrilton', slug: 'morrilton-ar' },
			{ name: 'Mountain Home', slug: 'mountain-home-ar' },
			{ name: 'Newport', slug: 'newport-ar' },
			{ name: 'North Little Rock', slug: 'north-little-rock-ar' },
			{ name: 'Osceola', slug: 'osceola-ar' },
			{ name: 'Paragould', slug: 'paragould-ar' },
			{ name: 'Pine Bluff', slug: 'pine-bluff-ar' },
			{ name: 'Rogers', slug: 'rogers-ar' },
			{ name: 'Russellville', slug: 'russellville-ar' },
			{ name: 'Searcy', slug: 'searcy-ar' },
			{ name: 'Sheridan', slug: 'sheridan-ar' },
			{ name: 'Sherwood', slug: 'sherwood-ar' },
			{ name: 'Siloam Springs', slug: 'siloam-springs-ar' },
			{ name: 'Springdale', slug: 'springdale-ar' },
			{ name: 'Stuttgart', slug: 'stuttgart-ar' },
			{ name: 'Texarkana', slug: 'texarkana-ar' },
			{ name: 'Trumann', slug: 'trumann-ar' },
			{ name: 'Van Buren', slug: 'van-buren-ar' },
			{ name: 'Vilonia', slug: 'vilonia-ar' },
			{ name: 'Walnut Ridge', slug: 'walnut-ridge-ar' },
			{ name: 'West Memphis', slug: 'west-memphis-ar' },
			{ name: 'Wynne', slug: 'wynne-ar' },
		],
		stateName: 'Arkansas',
	},
	CA: {
		cities: [
			{ name: 'Anaheim', slug: 'anaheim-ca' },
			{ name: 'Bakersfield', slug: 'bakersfield-ca' },
			{ name: 'Berkeley', slug: 'berkeley-ca' },
			{ name: 'Burbank', slug: 'burbank-ca' },
			{ name: 'Carlsbad', slug: 'carlsbad-ca' },
			{ name: 'Chula Vista', slug: 'chula-vista-ca' },
			{ name: 'Clovis', slug: 'clovis-ca' },
			{ name: 'Concord', slug: 'concord-ca' },
			{ name: 'Corona', slug: 'corona-ca' },
			{ name: 'Costa Mesa', slug: 'costa-mesa-ca' },
			{ name: 'El Cajon', slug: 'el-cajon-ca' },
			{ name: 'Elk Grove', slug: 'elk-grove-ca' },
			{ name: 'Escondido', slug: 'escondido-ca' },
			{ name: 'Fontana', slug: 'fontana-ca' },
			{ name: 'Fremont', slug: 'fremont-ca' },
			{ name: 'Fresno', slug: 'fresno-ca' },
			{ name: 'Fullerton', slug: 'fullerton-ca' },
			{ name: 'Glendale', slug: 'glendale-ca' },
			{ name: 'Hayward', slug: 'hayward-ca' },
			{ name: 'Huntington Beach', slug: 'huntington-beach-ca' },
			{ name: 'Irvine', slug: 'irvine-ca' },
			{ name: 'Livermore', slug: 'livermore-ca' },
			{ name: 'Long Beach', slug: 'long-beach-ca' },
			{ name: 'Los Angeles', slug: 'los-angeles-ca' },
			{ name: 'Mission Viejo', slug: 'mission-viejo-ca' },
			{ name: 'Modesto', slug: 'modesto-ca' },
			{ name: 'Murrieta', slug: 'murrieta-ca' },
			{ name: 'Oakland', slug: 'oakland-ca' },
			{ name: 'Oceanside', slug: 'oceanside-ca' },
			{ name: 'Ontario', slug: 'ontario-ca' },
			{ name: 'Orange', slug: 'orange-ca' },
			{ name: 'Oxnard', slug: 'oxnard-ca' },
			{ name: 'Pasadena', slug: 'pasadena-ca' },
			{ name: 'Rancho Cucamonga', slug: 'rancho-cucamonga-ca' },
			{ name: 'Richmond', slug: 'richmond-ca' },
			{ name: 'Riverside', slug: 'riverside-ca' },
			{ name: 'Roseville', slug: 'roseville-ca' },
			{ name: 'Sacramento', slug: 'sacramento-ca' },
			{ name: 'San Bernardino', slug: 'san-bernardino-ca' },
			{ name: 'San Diego', slug: 'san-diego-ca' },
			{ name: 'San Francisco', slug: 'san-francisco-ca' },
			{ name: 'San Jose', slug: 'san-jose-ca' },
			{ name: 'San Marcos', slug: 'san-marcos-ca' },
			{ name: 'San Mateo', slug: 'san-mateo-ca' },
			{ name: 'Santa Ana', slug: 'santa-ana-ca' },
			{ name: 'Santa Barbara', slug: 'santa-barbara-ca' },
			{ name: 'Santa Clara', slug: 'santa-clara-ca' },
			{ name: 'Santa Clarita', slug: 'santa-clarita-ca' },
			{ name: 'Santa Cruz', slug: 'santa-cruz-ca' },
			{ name: 'Santa Monica', slug: 'santa-monica-ca' },
			{ name: 'Santa Rosa', slug: 'santa-rosa-ca' },
			{ name: 'Simi Valley', slug: 'simi-valley-ca' },
			{ name: 'Stockton', slug: 'stockton-ca' },
			{ name: 'Sunnyvale', slug: 'sunnyvale-ca' },
			{ name: 'Temecula', slug: 'temecula-ca' },
			{ name: 'Thousand Oaks', slug: 'thousand-oaks-ca' },
			{ name: 'Torrance', slug: 'torrance-ca' },
			{ name: 'Visalia', slug: 'visalia-ca' },
		],
		stateName: 'California',
	},
	CO: {
		cities: [
			{ name: 'Alamosa', slug: 'alamosa-co' },
			{ name: 'Arapahoe', slug: 'arapahoe-co' },
			{ name: 'Arvada', slug: 'arvada-co' },
			{ name: 'Aspen', slug: 'aspen-co' },
			{ name: 'Aurora', slug: 'aurora-co' },
			{ name: 'Boulder', slug: 'boulder-co' },
			{ name: 'Breckenridge', slug: 'breckenridge-co' },
			{ name: 'Brighton', slug: 'brighton-co' },
			{ name: 'Broomfield', slug: 'broomfield-co' },
			{ name: 'Castle Rock', slug: 'castle-rock-co' },
			{ name: 'Centennial', slug: 'centennial-co' },
			{ name: 'Clifton', slug: 'clifton-co' },
			{ name: 'Colorado Springs', slug: 'colorado-springs-co' },
			{ name: 'Commerce City', slug: 'commerce-city-co' },
			{ name: 'Delta', slug: 'delta-co' },
			{ name: 'Denver', slug: 'denver-co' },
			{ name: 'Durango', slug: 'durango-co' },
			{ name: 'Eagle', slug: 'eagle-co' },
			{ name: 'Elbert', slug: 'elbert-co' },
			{ name: 'Englewood', slug: 'englewood-co' },
			{ name: 'Erie', slug: 'erie-co' },
			{ name: 'Estes Park', slug: 'estes-park-co' },
			{ name: 'Evans', slug: 'evans-co' },
			{ name: 'Firestone', slug: 'firestone-co' },
			{ name: 'Fort Collins', slug: 'fort-collins-co' },
			{ name: 'Fort Morgan', slug: 'fort-morgan-co' },
			{ name: 'Fountain', slug: 'fountain-co' },
			{ name: 'Frederick', slug: 'frederick-co' },
			{ name: 'Fruita', slug: 'fruita-co' },
			{ name: 'Glenwood Springs', slug: 'glenwood-springs-co' },
			{ name: 'Golden', slug: 'golden-co' },
			{ name: 'Grand Junction', slug: 'grand-junction-co' },
			{ name: 'Greeley', slug: 'greeley-co' },
			{ name: 'Gypsum', slug: 'gypsum-co' },
			{ name: 'Jefferson', slug: 'jefferson-co' },
			{ name: 'Johnstown', slug: 'johnstown-co' },
			{ name: 'Lafayette', slug: 'lafayette-co' },
			{ name: 'Lakewood', slug: 'lakewood-co' },
			{ name: 'Littleton', slug: 'littleton-co' },
			{ name: 'Lone Tree', slug: 'lone-tree-co' },
			{ name: 'Longmont', slug: 'longmont-co' },
			{ name: 'Louisville', slug: 'louisville-co' },
			{ name: 'Loveland', slug: 'loveland-co' },
			{ name: 'Mesa', slug: 'mesa-co' },
			{ name: 'Montrose', slug: 'montrose-co' },
			{ name: 'Pagosa Springs', slug: 'pagosa-springs-co' },
			{ name: 'Parker', slug: 'parker-co' },
			{ name: 'Pueblo', slug: 'pueblo-co' },
			{ name: 'Rifle', slug: 'rifle-co' },
			{ name: 'Silverthorne', slug: 'silverthorne-co' },
			{ name: 'Steamboat Springs', slug: 'steamboat-springs-co' },
			{ name: 'Sterling', slug: 'sterling-co' },
			{ name: 'Thornton', slug: 'thornton-co' },
			{ name: 'Vail', slug: 'vail-co' },
			{ name: 'Westminster', slug: 'westminster-co' },
			{ name: 'Wheat Ridge', slug: 'wheat-ridge-co' },
			{ name: 'Windsor', slug: 'windsor-co' },
			{ name: 'Woodland Park', slug: 'woodland-park-co' },
		],
		stateName: 'Colorado',
	},
	CT: {
		cities: [
			{ name: 'Ansonia', slug: 'ansonia-ct' },
			{ name: 'Bantam', slug: 'bantam-ct' },
			{ name: 'Bridgeport', slug: 'bridgeport-ct' },
			{ name: 'Bristol', slug: 'bristol-ct' },
			{ name: 'Danbury', slug: 'danbury-ct' },
			{ name: 'Danielson', slug: 'danielson-ct' },
			{ name: 'Derby', slug: 'derby-ct' },
			{ name: 'Fairfield', slug: 'fairfield-ct' },
			{ name: 'Greenwich', slug: 'greenwich-ct' },
			{ name: 'Groton', slug: 'groton-ct' },
			{ name: 'Hartford', slug: 'hartford-ct' },
			{ name: 'Jewett City', slug: 'jewett-city-ct' },
			{ name: 'Litchfield', slug: 'litchfield-ct' },
			{ name: 'Meriden', slug: 'meriden-ct' },
			{ name: 'Middletown', slug: 'middletown-ct' },
			{ name: 'Naugatuck', slug: 'naugatuck-ct' },
			{ name: 'New Britain', slug: 'new-britain-ct' },
			{ name: 'New Haven', slug: 'new-haven-ct' },
			{ name: 'New London', slug: 'new-london-ct' },
			{ name: 'Newtown', slug: 'newtown-ct' },
			{ name: 'Norwalk', slug: 'norwalk-ct' },
			{ name: 'Norwich', slug: 'norwich-ct' },
			{ name: 'Shelton', slug: 'shelton-ct' },
			{ name: 'Stamford', slug: 'stamford-ct' },
			{ name: 'Stonington', slug: 'stonington-ct' },
			{ name: 'Tolland', slug: 'tolland-ct' },
			{ name: 'Torrington', slug: 'torrington-ct' },
			{ name: 'Waterbury', slug: 'waterbury-ct' },
			{ name: 'West Haven', slug: 'west-haven-ct' },
			{ name: 'Windham', slug: 'windham-ct' },
		],
		stateName: 'Connecticut',
	},
	DE: {
		cities: [
			{ name: 'Arden', slug: 'arden-de' },
			{ name: 'Bear', slug: 'bear-de' },
			{ name: 'Bethany Beach', slug: 'bethany-beach-de' },
			{ name: 'Bethel', slug: 'bethel-de' },
			{ name: 'Bridgeville', slug: 'bridgeville-de' },
			{ name: 'Camden', slug: 'camden-de' },
			{ name: 'Cheswold', slug: 'cheswold-de' },
			{ name: 'Clayton', slug: 'clayton-de' },
			{ name: 'Dagsboro', slug: 'dagsboro-de' },
			{ name: 'Delaware City', slug: 'delaware-city-de' },
			{ name: 'Delmar', slug: 'delmar-de' },
			{ name: 'Dewey Beach', slug: 'dewey-beach-de' },
			{ name: 'Dover', slug: 'dover-de' },
			{ name: 'Ellendale', slug: 'ellendale-de' },
			{ name: 'Elsmere', slug: 'elsmere-de' },
			{ name: 'Felton', slug: 'felton-de' },
			{ name: 'Fenwick Island', slug: 'fenwick-island-de' },
			{ name: 'Frankford', slug: 'frankford-de' },
			{ name: 'Frederica', slug: 'frederica-de' },
			{ name: 'Georgetown', slug: 'georgetown-de' },
			{ name: 'Greenwood', slug: 'greenwood-de' },
			{ name: 'Harrington', slug: 'harrington-de' },
			{ name: 'Hartly', slug: 'hartly-de' },
			{ name: 'Houston', slug: 'houston-de' },
			{ name: 'Kenton', slug: 'kenton-de' },
			{ name: 'Kirkwood', slug: 'kirkwood-de' },
			{ name: 'Laurel', slug: 'laurel-de' },
			{ name: 'Lewes', slug: 'lewes-de' },
			{ name: 'Lincoln', slug: 'lincoln-de' },
			{ name: 'Little Creek', slug: 'little-creek-de' },
			{ name: 'Magnolia', slug: 'magnolia-de' },
			{ name: 'Marydel', slug: 'marydel-de' },
			{ name: 'Middletown', slug: 'middletown-de' },
			{ name: 'Milford', slug: 'milford-de' },
			{ name: 'Millsboro', slug: 'millsboro-de' },
			{ name: 'Millville', slug: 'millville-de' },
			{ name: 'Milton', slug: 'milton-de' },
			{ name: 'Montchanin', slug: 'montchanin-de' },
			{ name: 'New Castle', slug: 'new-castle-de' },
			{ name: 'Newark', slug: 'newark-de' },
			{ name: 'Newport', slug: 'newport-de' },
			{ name: 'Ocean View', slug: 'ocean-view-de' },
			{ name: 'Odessa', slug: 'odessa-de' },
			{ name: 'Port Penn', slug: 'port-penn-de' },
			{ name: 'Rehoboth Beach', slug: 'rehoboth-beach-de' },
			{ name: 'Rockland', slug: 'rockland-de' },
			{ name: 'Seaford', slug: 'seaford-de' },
			{ name: 'Selbyville', slug: 'selbyville-de' },
			{ name: 'Slaughter Beach', slug: 'slaughter-beach-de' },
			{ name: 'Smyrna', slug: 'smyrna-de' },
			{ name: 'Townsend', slug: 'townsend-de' },
			{ name: 'Viola', slug: 'viola-de' },
			{ name: 'Wilmington', slug: 'wilmington-de' },
			{ name: 'Winterthur', slug: 'winterthur-de' },
			{ name: 'Woodside', slug: 'woodside-de' },
			{ name: 'Wyoming', slug: 'wyoming-de' },
		],
		stateName: 'Delaware',
	},
	DC: {
		cities: [{ name: 'Washington', slug: 'washington-dc' }],
		stateName: 'District of Columbia',
	},
	FL: {
		cities: [
			{ name: 'Altamonte Springs', slug: 'altamonte-springs-fl' },
			{ name: 'Apopka', slug: 'apopka-fl' },
			{ name: 'Boca Raton', slug: 'boca-raton-fl' },
			{ name: 'Bonita Springs', slug: 'bonita-springs-fl' },
			{ name: 'Boynton Beach', slug: 'boynton-beach-fl' },
			{ name: 'Bradenton', slug: 'bradenton-fl' },
			{ name: 'Cape Coral', slug: 'cape-coral-fl' },
			{ name: 'Clearwater', slug: 'clearwater-fl' },
			{ name: 'Clermont', slug: 'clermont-fl' },
			{ name: 'Coral Gables', slug: 'coral-gables-fl' },
			{ name: 'Coral Springs', slug: 'coral-springs-fl' },
			{ name: 'Day', slug: 'day-fl' },
			{ name: 'Daytona Beach', slug: 'daytona-beach-fl' },
			{ name: 'Deerfield Beach', slug: 'deerfield-beach-fl' },
			{ name: 'DeLand', slug: 'deland-fl' },
			{ name: 'Delray Beach', slug: 'delray-beach-fl' },
			{ name: 'Deltona', slug: 'deltona-fl' },
			{ name: 'Doral', slug: 'doral-fl' },
			{ name: 'Fort Lauderdale', slug: 'fort-lauderdale-fl' },
			{ name: 'Fort Myers', slug: 'fort-myers-fl' },
			{ name: 'Gainesville', slug: 'gainesville-fl' },
			{ name: 'Hernando', slug: 'hernando-fl' },
			{ name: 'Hialeah', slug: 'hialeah-fl' },
			{ name: 'Hollywood', slug: 'hollywood-fl' },
			{ name: 'Homestead', slug: 'homestead-fl' },
			{ name: 'Jacksonville', slug: 'jacksonville-fl' },
			{ name: 'Jupiter', slug: 'jupiter-fl' },
			{ name: 'Kissimmee', slug: 'kissimmee-fl' },
			{ name: 'Lakeland', slug: 'lakeland-fl' },
			{ name: 'Largo', slug: 'largo-fl' },
			{ name: 'Margate', slug: 'margate-fl' },
			{ name: 'Melbourne', slug: 'melbourne-fl' },
			{ name: 'Miami Beach', slug: 'miami-beach-fl' },
			{ name: 'Miami Gardens', slug: 'miami-gardens-fl' },
			{ name: 'Miami', slug: 'miami-fl' },
			{ name: 'Ocala', slug: 'ocala-fl' },
			{ name: 'Orlando', slug: 'orlando-fl' },
			{ name: 'Oviedo', slug: 'oviedo-fl' },
			{ name: 'Palm Bay', slug: 'palm-bay-fl' },
			{ name: 'Palm Coast', slug: 'palm-coast-fl' },
			{ name: 'Pembroke Pines', slug: 'pembroke-pines-fl' },
			{ name: 'Pensacola', slug: 'pensacola-fl' },
			{ name: 'Pinellas Park', slug: 'pinellas-park-fl' },
			{ name: 'Plantation', slug: 'plantation-fl' },
			{ name: 'Pompano Beach', slug: 'pompano-beach-fl' },
			{ name: 'Port Orange', slug: 'port-orange-fl' },
			{ name: 'Rockledge', slug: 'rockledge-fl' },
			{ name: 'Royal Palm Beach', slug: 'royal-palm-beach-fl' },
			{ name: 'Sanford', slug: 'sanford-fl' },
			{ name: 'Sarasota', slug: 'sarasota-fl' },
			{ name: 'Sunrise', slug: 'sunrise-fl' },
			{ name: 'Tallahassee', slug: 'tallahassee-fl' },
			{ name: 'Tampa', slug: 'tampa-fl' },
			{ name: 'Wellington', slug: 'wellington-fl' },
			{ name: 'West Palm Beach', slug: 'west-palm-beach-fl' },
			{ name: 'Winter Garden', slug: 'winter-garden-fl' },
			{ name: 'Winter Park', slug: 'winter-park-fl' },
			{ name: 'Winter Springs', slug: 'winter-springs-fl' },
		],
		stateName: 'Florida',
	},
	GA: {
		cities: [
			{ name: 'Acworth', slug: 'acworth-ga' },
			{ name: 'Albany', slug: 'albany-ga' },
			{ name: 'Alpharetta', slug: 'alpharetta-ga' },
			{ name: 'Appling', slug: 'appling-ga' },
			{ name: 'Athens', slug: 'athens-ga' },
			{ name: 'Atlanta', slug: 'atlanta-ga' },
			{ name: 'Augusta', slug: 'augusta-ga' },
			{ name: 'Brunswick', slug: 'brunswick-ga' },
			{ name: 'Buford', slug: 'buford-ga' },
			{ name: 'Calhoun', slug: 'calhoun-ga' },
			{ name: 'Canton', slug: 'canton-ga' },
			{ name: 'Carrollton', slug: 'carrollton-ga' },
			{ name: 'Cartersville', slug: 'cartersville-ga' },
			{ name: 'Columbus', slug: 'columbus-ga' },
			{ name: 'Conyers', slug: 'conyers-ga' },
			{ name: 'Dallas', slug: 'dallas-ga' },
			{ name: 'Dalton', slug: 'dalton-ga' },
			{ name: 'Decatur', slug: 'decatur-ga' },
			{ name: 'Douglasville', slug: 'douglasville-ga' },
			{ name: 'Duluth', slug: 'duluth-ga' },
			{ name: 'Fayetteville', slug: 'fayetteville-ga' },
			{ name: 'Forest Park', slug: 'forest-park-ga' },
			{ name: 'Gainesville', slug: 'gainesville-ga' },
			{ name: 'Griffin', slug: 'griffin-ga' },
			{ name: 'Grovetown', slug: 'grovetown-ga' },
			{ name: 'Hinesville', slug: 'hinesville-ga' },
			{ name: 'Jefferson', slug: 'jefferson-ga' },
			{ name: 'Kennesaw', slug: 'kennesaw-ga' },
			{ name: 'Lawrenceville', slug: 'lawrenceville-ga' },
			{ name: 'Lilburn', slug: 'lilburn-ga' },
			{ name: 'Loganville', slug: 'loganville-ga' },
			{ name: 'Macon', slug: 'macon-ga' },
			{ name: 'Marietta', slug: 'marietta-ga' },
			{ name: 'McDonough', slug: 'mcdonough-ga' },
			{ name: 'Milledgeville', slug: 'milledgeville-ga' },
			{ name: 'Newnan', slug: 'newnan-ga' },
			{ name: 'Norcross', slug: 'norcross-ga' },
			{ name: 'Peachtree City', slug: 'peachtree-city-ga' },
			{ name: 'Peachtree Corners', slug: 'peachtree-corners-ga' },
			{ name: 'Pooler', slug: 'pooler-ga' },
			{ name: 'Powder Springs', slug: 'powder-springs-ga' },
			{ name: 'Riverdale', slug: 'riverdale-ga' },
			{ name: 'Rome', slug: 'rome-ga' },
			{ name: 'Roswell', slug: 'roswell-ga' },
			{ name: 'Savannah', slug: 'savannah-ga' },
			{ name: 'Smyrna', slug: 'smyrna-ga' },
			{ name: 'Snellville', slug: 'snellville-ga' },
			{ name: 'Statesboro', slug: 'statesboro-ga' },
			{ name: 'Stephens', slug: 'stephens-ga' },
			{ name: 'Stockbridge', slug: 'stockbridge-ga' },
			{ name: 'Suwanee', slug: 'suwanee-ga' },
			{ name: 'Thomasville', slug: 'thomasville-ga' },
			{ name: 'Toccoa', slug: 'toccoa-ga' },
			{ name: 'Union City', slug: 'union-city-ga' },
			{ name: 'Valdosta', slug: 'valdosta-ga' },
			{ name: 'Warner Robins', slug: 'warner-robins-ga' },
			{ name: 'Winder', slug: 'winder-ga' },
			{ name: 'Woodstock', slug: 'woodstock-ga' },
		],
		stateName: 'Georgia',
	},
	HI: {
		cities: [{ name: 'Honolulu', slug: 'honolulu-hi' }],
		stateName: 'Hawaii',
	},
	ID: {
		cities: [
			{ name: 'American Falls', slug: 'american-falls-id' },
			{ name: 'Bellevue', slug: 'bellevue-id' },
			{ name: 'Blackfoot', slug: 'blackfoot-id' },
			{ name: 'Boise', slug: 'boise-id' },
			{ name: 'Bonners Ferry', slug: 'bonners-ferry-id' },
			{ name: 'Buhl', slug: 'buhl-id' },
			{ name: 'Burley', slug: 'burley-id' },
			{ name: 'Caldwell', slug: 'caldwell-id' },
			{ name: "Coeur d'Alene", slug: "coeur-d'alene-id" },
			{ name: 'Eagle', slug: 'eagle-id' },
			{ name: 'Emmett', slug: 'emmett-id' },
			{ name: 'Filer', slug: 'filer-id' },
			{ name: 'Fruitland', slug: 'fruitland-id' },
			{ name: 'Garden City', slug: 'garden-city-id' },
			{ name: 'Gooding', slug: 'gooding-id' },
			{ name: 'Grangeville', slug: 'grangeville-id' },
			{ name: 'Hailey', slug: 'hailey-id' },
			{ name: 'Hayden', slug: 'hayden-id' },
			{ name: 'Heyburn', slug: 'heyburn-id' },
			{ name: 'Homedale', slug: 'homedale-id' },
			{ name: 'Idaho Falls', slug: 'idaho-falls-id' },
			{ name: 'Iona', slug: 'iona-id' },
			{ name: 'Jerome', slug: 'jerome-id' },
			{ name: 'Kellogg', slug: 'kellogg-id' },
			{ name: 'Ketchum', slug: 'ketchum-id' },
			{ name: 'Kimberly', slug: 'kimberly-id' },
			{ name: 'Kootenai', slug: 'kootenai-id' },
			{ name: 'Kuna', slug: 'kuna-id' },
			{ name: 'Lemhi', slug: 'lemhi-id' },
			{ name: 'Lewiston', slug: 'lewiston-id' },
			{ name: 'McCall', slug: 'mccall-id' },
			{ name: 'Meridian', slug: 'meridian-id' },
			{ name: 'Middleton', slug: 'middleton-id' },
			{ name: 'Montpelier', slug: 'montpelier-id' },
			{ name: 'Moscow', slug: 'moscow-id' },
			{ name: 'Mountain Home', slug: 'mountain-home-id' },
			{ name: 'Nampa', slug: 'nampa-id' },
			{ name: 'Orofino', slug: 'orofino-id' },
			{ name: 'Osburn', slug: 'osburn-id' },
			{ name: 'Payette', slug: 'payette-id' },
			{ name: 'Pocatello', slug: 'pocatello-id' },
			{ name: 'Post Falls', slug: 'post-falls-id' },
			{ name: 'Preston', slug: 'preston-id' },
			{ name: 'Rathdrum', slug: 'rathdrum-id' },
			{ name: 'Rexburg', slug: 'rexburg-id' },
			{ name: 'Rigby', slug: 'rigby-id' },
			{ name: 'Rupert', slug: 'rupert-id' },
			{ name: 'Saint Anthony', slug: 'saint-anthony-id' },
			{ name: 'Saint Maries', slug: 'saint-maries-id' },
			{ name: 'Salmon', slug: 'salmon-id' },
			{ name: 'Sandpoint', slug: 'sandpoint-id' },
			{ name: 'Shelley', slug: 'shelley-id' },
			{ name: 'Soda Springs', slug: 'soda-springs-id' },
			{ name: 'Star', slug: 'star-id' },
			{ name: 'Teton', slug: 'teton-id' },
			{ name: 'Twin Falls', slug: 'twin-falls-id' },
			{ name: 'Weiser', slug: 'weiser-id' },
			{ name: 'Wendell', slug: 'wendell-id' },
		],
		stateName: 'Idaho',
	},
	IL: {
		cities: [
			{ name: 'Addison', slug: 'addison-il' },
			{ name: 'Arlington Heights', slug: 'arlington-heights-il' },
			{ name: 'Aurora', slug: 'aurora-il' },
			{ name: 'Bartlett', slug: 'bartlett-il' },
			{ name: 'Batavia', slug: 'batavia-il' },
			{ name: 'Belleville', slug: 'belleville-il' },
			{ name: 'Berwyn', slug: 'berwyn-il' },
			{ name: 'Bloomington', slug: 'bloomington-il' },
			{ name: 'Bolingbrook', slug: 'bolingbrook-il' },
			{ name: 'Buffalo Grove', slug: 'buffalo-grove-il' },
			{ name: 'Carol Stream', slug: 'carol-stream-il' },
			{ name: 'Champaign', slug: 'champaign-il' },
			{ name: 'Chicago', slug: 'chicago-il' },
			{ name: 'Collinsville', slug: 'collinsville-il' },
			{ name: 'Crystal Lake', slug: 'crystal-lake-il' },
			{ name: 'Decatur', slug: 'decatur-il' },
			{ name: 'DeKalb', slug: 'dekalb-il' },
			{ name: 'Des Plaines', slug: 'des-plaines-il' },
			{ name: 'Downers Grove', slug: 'downers-grove-il' },
			{ name: 'Edwardsville', slug: 'edwardsville-il' },
			{ name: 'Elgin', slug: 'elgin-il' },
			{ name: 'Elk Grove Village', slug: 'elk-grove-village-il' },
			{ name: 'Elmhurst', slug: 'elmhurst-il' },
			{ name: 'Evanston', slug: 'evanston-il' },
			{ name: 'Glen Ellyn', slug: 'glen-ellyn-il' },
			{ name: 'Glenview', slug: 'glenview-il' },
			{ name: 'Gurnee', slug: 'gurnee-il' },
			{ name: 'Highland Park', slug: 'highland-park-il' },
			{ name: 'Hoffman Estates', slug: 'hoffman-estates-il' },
			{ name: 'Joliet', slug: 'joliet-il' },
			{ name: 'Lockport', slug: 'lockport-il' },
			{ name: 'Lombard', slug: 'lombard-il' },
			{ name: 'McHenry', slug: 'mchenry-il' },
			{ name: 'Moline', slug: 'moline-il' },
			{ name: 'Mount Prospect', slug: 'mount-prospect-il' },
			{ name: 'Naperville', slug: 'naperville-il' },
			{ name: 'New Lenox', slug: 'new-lenox-il' },
			{ name: 'Normal', slug: 'normal-il' },
			{ name: 'Northbrook', slug: 'northbrook-il' },
			{ name: "O'Fallon", slug: "o'fallon-il" },
			{ name: 'Oak Lawn', slug: 'oak-lawn-il' },
			{ name: 'Oak Park', slug: 'oak-park-il' },
			{ name: 'Orland Park', slug: 'orland-park-il' },
			{ name: 'Oswego', slug: 'oswego-il' },
			{ name: 'Palatine', slug: 'palatine-il' },
			{ name: 'Park Ridge', slug: 'park-ridge-il' },
			{ name: 'Peoria', slug: 'peoria-il' },
			{ name: 'Plainfield', slug: 'plainfield-il' },
			{ name: 'Rockford', slug: 'rockford-il' },
			{ name: 'Schaumburg', slug: 'schaumburg-il' },
			{ name: 'Skokie', slug: 'skokie-il' },
			{ name: 'Springfield', slug: 'springfield-il' },
			{ name: 'Tinley Park', slug: 'tinley-park-il' },
			{ name: 'Urbana', slug: 'urbana-il' },
			{ name: 'Vernon Hills', slug: 'vernon-hills-il' },
			{ name: 'Waukegan', slug: 'waukegan-il' },
			{ name: 'Wheaton', slug: 'wheaton-il' },
			{ name: 'Wheeling', slug: 'wheeling-il' },
		],
		stateName: 'Illinois',
	},
	IN: {
		cities: [
			{ name: 'Anderson', slug: 'anderson-in' },
			{ name: 'Angola', slug: 'angola-in' },
			{ name: 'Auburn', slug: 'auburn-in' },
			{ name: 'Avon', slug: 'avon-in' },
			{ name: 'Bloomington', slug: 'bloomington-in' },
			{ name: 'Brazil', slug: 'brazil-in' },
			{ name: 'Brownsburg', slug: 'brownsburg-in' },
			{ name: 'Carmel', slug: 'carmel-in' },
			{ name: 'Cedar Lake', slug: 'cedar-lake-in' },
			{ name: 'Chesterton', slug: 'chesterton-in' },
			{ name: 'Columbia City', slug: 'columbia-city-in' },
			{ name: 'Columbus', slug: 'columbus-in' },
			{ name: 'Crawfordsville', slug: 'crawfordsville-in' },
			{ name: 'Crown Point', slug: 'crown-point-in' },
			{ name: 'Elkhart', slug: 'elkhart-in' },
			{ name: 'Evansville', slug: 'evansville-in' },
			{ name: 'Fishers', slug: 'fishers-in' },
			{ name: 'Fort Wayne', slug: 'fort-wayne-in' },
			{ name: 'Franklin', slug: 'franklin-in' },
			{ name: 'Gary', slug: 'gary-in' },
			{ name: 'Goshen', slug: 'goshen-in' },
			{ name: 'Greenfield', slug: 'greenfield-in' },
			{ name: 'Greenwood', slug: 'greenwood-in' },
			{ name: 'Hammond', slug: 'hammond-in' },
			{ name: 'Highland', slug: 'highland-in' },
			{ name: 'Hobart', slug: 'hobart-in' },
			{ name: 'Indianapolis', slug: 'indianapolis-in' },
			{ name: 'Jasper', slug: 'jasper-in' },
			{ name: 'Jeffersonville', slug: 'jeffersonville-in' },
			{ name: 'Kendallville', slug: 'kendallville-in' },
			{ name: 'Kokomo', slug: 'kokomo-in' },
			{ name: 'Lafayette', slug: 'lafayette-in' },
			{ name: 'Marion', slug: 'marion-in' },
			{ name: 'Martinsville', slug: 'martinsville-in' },
			{ name: 'Merrillville', slug: 'merrillville-in' },
			{ name: 'Michigan City', slug: 'michigan-city-in' },
			{ name: 'Mishawaka', slug: 'mishawaka-in' },
			{ name: 'Mooresville', slug: 'mooresville-in' },
			{ name: 'Muncie', slug: 'muncie-in' },
			{ name: 'Munster', slug: 'munster-in' },
			{ name: 'New Albany', slug: 'new-albany-in' },
			{ name: 'Noblesville', slug: 'noblesville-in' },
			{ name: 'Plainfield', slug: 'plainfield-in' },
			{ name: 'Portage', slug: 'portage-in' },
			{ name: 'Richmond', slug: 'richmond-in' },
			{ name: 'Saint John', slug: 'saint-john-in' },
			{ name: 'Schererville', slug: 'schererville-in' },
			{ name: 'Sellersburg', slug: 'sellersburg-in' },
			{ name: 'Seymour', slug: 'seymour-in' },
			{ name: 'Shelbyville', slug: 'shelbyville-in' },
			{ name: 'South Bend', slug: 'south-bend-in' },
			{ name: 'Syracuse', slug: 'syracuse-in' },
			{ name: 'Terre Haute', slug: 'terre-haute-in' },
			{ name: 'Valparaiso', slug: 'valparaiso-in' },
			{ name: 'Warsaw', slug: 'warsaw-in' },
			{ name: 'West Lafayette', slug: 'west-lafayette-in' },
			{ name: 'Westfield', slug: 'westfield-in' },
			{ name: 'Zionsville', slug: 'zionsville-in' },
		],
		stateName: 'Indiana',
	},
	IA: {
		cities: [
			{ name: 'Altoona', slug: 'altoona-ia' },
			{ name: 'Ames', slug: 'ames-ia' },
			{ name: 'Ankeny', slug: 'ankeny-ia' },
			{ name: 'Bettendorf', slug: 'bettendorf-ia' },
			{ name: 'Boone', slug: 'boone-ia' },
			{ name: 'Burlington', slug: 'burlington-ia' },
			{ name: 'Carroll', slug: 'carroll-ia' },
			{ name: 'Cedar Falls', slug: 'cedar-falls-ia' },
			{ name: 'Cedar Rapids', slug: 'cedar-rapids-ia' },
			{ name: 'Charles City', slug: 'charles-city-ia' },
			{ name: 'Clear Lake', slug: 'clear-lake-ia' },
			{ name: 'Clinton', slug: 'clinton-ia' },
			{ name: 'Clive', slug: 'clive-ia' },
			{ name: 'Coralville', slug: 'coralville-ia' },
			{ name: 'Council Bluffs', slug: 'council-bluffs-ia' },
			{ name: 'Creston', slug: 'creston-ia' },
			{ name: 'Davenport', slug: 'davenport-ia' },
			{ name: 'Decorah', slug: 'decorah-ia' },
			{ name: 'Denison', slug: 'denison-ia' },
			{ name: 'Des Moines', slug: 'des-moines-ia' },
			{ name: 'Dubuque', slug: 'dubuque-ia' },
			{ name: 'Fairfield', slug: 'fairfield-ia' },
			{ name: 'Fort Dodge', slug: 'fort-dodge-ia' },
			{ name: 'Fort Madison', slug: 'fort-madison-ia' },
			{ name: 'Grimes', slug: 'grimes-ia' },
			{ name: 'Grinnell', slug: 'grinnell-ia' },
			{ name: 'Hiawatha', slug: 'hiawatha-ia' },
			{ name: 'Indianola', slug: 'indianola-ia' },
			{ name: 'Iowa City', slug: 'iowa-city-ia' },
			{ name: 'Johnston', slug: 'johnston-ia' },
			{ name: 'Keokuk', slug: 'keokuk-ia' },
			{ name: 'Knoxville', slug: 'knoxville-ia' },
			{ name: 'Le Mars', slug: 'le-mars-ia' },
			{ name: 'Marion', slug: 'marion-ia' },
			{ name: 'Marshalltown', slug: 'marshalltown-ia' },
			{ name: 'Mason City', slug: 'mason-city-ia' },
			{ name: 'Mount Pleasant', slug: 'mount-pleasant-ia' },
			{ name: 'Muscatine', slug: 'muscatine-ia' },
			{ name: 'Newton', slug: 'newton-ia' },
			{ name: 'North Liberty', slug: 'north-liberty-ia' },
			{ name: 'Norwalk', slug: 'norwalk-ia' },
			{ name: 'Orange City', slug: 'orange-city-ia' },
			{ name: 'Oskaloosa', slug: 'oskaloosa-ia' },
			{ name: 'Ottumwa', slug: 'ottumwa-ia' },
			{ name: 'Pella', slug: 'pella-ia' },
			{ name: 'Perry', slug: 'perry-ia' },
			{ name: 'Pleasant Hill', slug: 'pleasant-hill-ia' },
			{ name: 'Sioux Center', slug: 'sioux-center-ia' },
			{ name: 'Sioux City', slug: 'sioux-city-ia' },
			{ name: 'Spencer', slug: 'spencer-ia' },
			{ name: 'Spirit Lake', slug: 'spirit-lake-ia' },
			{ name: 'Storm Lake', slug: 'storm-lake-ia' },
			{ name: 'Urbandale', slug: 'urbandale-ia' },
			{ name: 'Waterloo', slug: 'waterloo-ia' },
			{ name: 'Waukee', slug: 'waukee-ia' },
			{ name: 'Waverly', slug: 'waverly-ia' },
			{ name: 'Webster City', slug: 'webster-city-ia' },
			{ name: 'West Des Moines', slug: 'west-des-moines-ia' },
		],
		stateName: 'Iowa',
	},
	KS: {
		cities: [
			{ name: 'Abilene', slug: 'abilene-ks' },
			{ name: 'Andover', slug: 'andover-ks' },
			{ name: 'Arkansas City', slug: 'arkansas-city-ks' },
			{ name: 'Atchison', slug: 'atchison-ks' },
			{ name: 'Augusta', slug: 'augusta-ks' },
			{ name: 'Baldwin City', slug: 'baldwin-city-ks' },
			{ name: 'Basehor', slug: 'basehor-ks' },
			{ name: 'Bonner Springs', slug: 'bonner-springs-ks' },
			{ name: 'Chanute', slug: 'chanute-ks' },
			{ name: 'Coffeyville', slug: 'coffeyville-ks' },
			{ name: 'Colby', slug: 'colby-ks' },
			{ name: 'Derby', slug: 'derby-ks' },
			{ name: 'Dodge City', slug: 'dodge-city-ks' },
			{ name: 'El Dorado', slug: 'el-dorado-ks' },
			{ name: 'Emporia', slug: 'emporia-ks' },
			{ name: 'Eudora', slug: 'eudora-ks' },
			{ name: 'Fort Scott', slug: 'fort-scott-ks' },
			{ name: 'Garden City', slug: 'garden-city-ks' },
			{ name: 'Gardner', slug: 'gardner-ks' },
			{ name: 'Great Bend', slug: 'great-bend-ks' },
			{ name: 'Hays', slug: 'hays-ks' },
			{ name: 'Haysville', slug: 'haysville-ks' },
			{ name: 'Hutchinson', slug: 'hutchinson-ks' },
			{ name: 'Independence', slug: 'independence-ks' },
			{ name: 'Johnson', slug: 'johnson-ks' },
			{ name: 'Junction City', slug: 'junction-city-ks' },
			{ name: 'Kansas City', slug: 'kansas-city-ks' },
			{ name: 'Lansing', slug: 'lansing-ks' },
			{ name: 'Lawrence', slug: 'lawrence-ks' },
			{ name: 'Leavenworth', slug: 'leavenworth-ks' },
			{ name: 'Leawood', slug: 'leawood-ks' },
			{ name: 'Lenexa', slug: 'lenexa-ks' },
			{ name: 'Liberal', slug: 'liberal-ks' },
			{ name: 'Manhattan', slug: 'manhattan-ks' },
			{ name: 'McPherson', slug: 'mcpherson-ks' },
			{ name: 'Merriam', slug: 'merriam-ks' },
			{ name: 'Mission', slug: 'mission-ks' },
			{ name: 'Mulvane', slug: 'mulvane-ks' },
			{ name: 'Newton', slug: 'newton-ks' },
			{ name: 'Olathe', slug: 'olathe-ks' },
			{ name: 'Ottawa', slug: 'ottawa-ks' },
			{ name: 'Overland Park', slug: 'overland-park-ks' },
			{ name: 'Parsons', slug: 'parsons-ks' },
			{ name: 'Pittsburg', slug: 'pittsburg-ks' },
			{ name: 'Prairie Village', slug: 'prairie-village-ks' },
			{ name: 'Pratt', slug: 'pratt-ks' },
			{ name: 'Salina', slug: 'salina-ks' },
			{ name: 'Sedgwick', slug: 'sedgwick-ks' },
			{ name: 'Shawnee', slug: 'shawnee-ks' },
			{ name: 'Spring Hill', slug: 'spring-hill-ks' },
			{ name: 'Tonganoxie', slug: 'tonganoxie-ks' },
			{ name: 'Topeka', slug: 'topeka-ks' },
			{ name: 'Ulysses', slug: 'ulysses-ks' },
			{ name: 'Valley Center', slug: 'valley-center-ks' },
			{ name: 'Wamego', slug: 'wamego-ks' },
			{ name: 'Wellington', slug: 'wellington-ks' },
			{ name: 'Wichita', slug: 'wichita-ks' },
			{ name: 'Winfield', slug: 'winfield-ks' },
		],
		stateName: 'Kansas',
	},
	KY: {
		cities: [
			{ name: 'Alexandria', slug: 'alexandria-ky' },
			{ name: 'Ashland', slug: 'ashland-ky' },
			{ name: 'Bardstown', slug: 'bardstown-ky' },
			{ name: 'Berea', slug: 'berea-ky' },
			{ name: 'Bowling Green', slug: 'bowling-green-ky' },
			{ name: 'Campbellsville', slug: 'campbellsville-ky' },
			{ name: 'Carter', slug: 'carter-ky' },
			{ name: 'Corbin', slug: 'corbin-ky' },
			{ name: 'Covington', slug: 'covington-ky' },
			{ name: 'Crestwood', slug: 'crestwood-ky' },
			{ name: 'Danville', slug: 'danville-ky' },
			{ name: 'Erlanger', slug: 'erlanger-ky' },
			{ name: 'Florence', slug: 'florence-ky' },
			{ name: 'Fort Thomas', slug: 'fort-thomas-ky' },
			{ name: 'Frankfort', slug: 'frankfort-ky' },
			{ name: 'Franklin', slug: 'franklin-ky' },
			{ name: 'Garrard', slug: 'garrard-ky' },
			{ name: 'Georgetown', slug: 'georgetown-ky' },
			{ name: 'Glasgow', slug: 'glasgow-ky' },
			{ name: 'Grayson', slug: 'grayson-ky' },
			{ name: 'Harrodsburg', slug: 'harrodsburg-ky' },
			{ name: 'Hazard', slug: 'hazard-ky' },
			{ name: 'Henderson', slug: 'henderson-ky' },
			{ name: 'Hopkinsville', slug: 'hopkinsville-ky' },
			{ name: 'Independence', slug: 'independence-ky' },
			{ name: 'La Grange', slug: 'la-grange-ky' },
			{ name: 'Lawrenceburg', slug: 'lawrenceburg-ky' },
			{ name: 'Lebanon', slug: 'lebanon-ky' },
			{ name: 'Letcher', slug: 'letcher-ky' },
			{ name: 'Lexington', slug: 'lexington-ky' },
			{ name: 'London', slug: 'london-ky' },
			{ name: 'Louisville', slug: 'louisville-ky' },
			{ name: 'Madisonville', slug: 'madisonville-ky' },
			{ name: 'Manchester', slug: 'manchester-ky' },
			{ name: 'Mason', slug: 'mason-ky' },
			{ name: 'Mayfield', slug: 'mayfield-ky' },
			{ name: 'Middlesboro', slug: 'middlesboro-ky' },
			{ name: 'Morehead', slug: 'morehead-ky' },
			{ name: 'Mount Sterling', slug: 'mount-sterling-ky' },
			{ name: 'Mount Washington', slug: 'mount-washington-ky' },
			{ name: 'Murray', slug: 'murray-ky' },
			{ name: 'Newport', slug: 'newport-ky' },
			{ name: 'Nicholasville', slug: 'nicholasville-ky' },
			{ name: 'Owensboro', slug: 'owensboro-ky' },
			{ name: 'Paducah', slug: 'paducah-ky' },
			{ name: 'Pendleton', slug: 'pendleton-ky' },
			{ name: 'Pikeville', slug: 'pikeville-ky' },
			{ name: 'Prestonsburg', slug: 'prestonsburg-ky' },
			{ name: 'Radcliff', slug: 'radcliff-ky' },
			{ name: 'Richmond', slug: 'richmond-ky' },
			{ name: 'Russellville', slug: 'russellville-ky' },
			{ name: 'Shelbyville', slug: 'shelbyville-ky' },
			{ name: 'Shepherdsville', slug: 'shepherdsville-ky' },
			{ name: 'Somerset', slug: 'somerset-ky' },
			{ name: 'Union', slug: 'union-ky' },
			{ name: 'Versailles', slug: 'versailles-ky' },
			{ name: 'Webster', slug: 'webster-ky' },
			{ name: 'Winchester', slug: 'winchester-ky' },
		],
		stateName: 'Kentucky',
	},
	LA: {
		cities: [
			{ name: 'Abbeville', slug: 'abbeville-la' },
			{ name: 'Alexandria', slug: 'alexandria-la' },
			{ name: 'Baker', slug: 'baker-la' },
			{ name: 'Bastrop', slug: 'bastrop-la' },
			{ name: 'Baton Rouge', slug: 'baton-rouge-la' },
			{ name: 'Bogalusa', slug: 'bogalusa-la' },
			{ name: 'Bossier City', slug: 'bossier-city-la' },
			{ name: 'Breaux Bridge', slug: 'breaux-bridge-la' },
			{ name: 'Broussard', slug: 'broussard-la' },
			{ name: 'Carencro', slug: 'carencro-la' },
			{ name: 'Covington', slug: 'covington-la' },
			{ name: 'Crowley', slug: 'crowley-la' },
			{ name: 'Denham Springs', slug: 'denham-springs-la' },
			{ name: 'Donaldsonville', slug: 'donaldsonville-la' },
			{ name: 'Eunice', slug: 'eunice-la' },
			{ name: 'Evangeline', slug: 'evangeline-la' },
			{ name: 'Franklin', slug: 'franklin-la' },
			{ name: 'Franklinton', slug: 'franklinton-la' },
			{ name: 'Gonzales', slug: 'gonzales-la' },
			{ name: 'Gramercy', slug: 'gramercy-la' },
			{ name: 'Gretna', slug: 'gretna-la' },
			{ name: 'Hammond', slug: 'hammond-la' },
			{ name: 'Houma', slug: 'houma-la' },
			{ name: 'Jena', slug: 'jena-la' },
			{ name: 'Jennings', slug: 'jennings-la' },
			{ name: 'Kenner', slug: 'kenner-la' },
			{ name: 'Lafayette', slug: 'lafayette-la' },
			{ name: 'Lake Charles', slug: 'lake-charles-la' },
			{ name: 'Leesville', slug: 'leesville-la' },
			{ name: 'Livingston', slug: 'livingston-la' },
			{ name: 'Mandeville', slug: 'mandeville-la' },
			{ name: 'Marksville', slug: 'marksville-la' },
			{ name: 'Minden', slug: 'minden-la' },
			{ name: 'Monroe', slug: 'monroe-la' },
			{ name: 'Morgan City', slug: 'morgan-city-la' },
			{ name: 'Natchitoches', slug: 'natchitoches-la' },
			{ name: 'New Iberia', slug: 'new-iberia-la' },
			{ name: 'New Orleans', slug: 'new-orleans-la' },
			{ name: 'Opelousas', slug: 'opelousas-la' },
			{ name: 'Patterson', slug: 'patterson-la' },
			{ name: 'Pineville', slug: 'pineville-la' },
			{ name: 'Plaquemine', slug: 'plaquemine-la' },
			{ name: 'Ponchatoula', slug: 'ponchatoula-la' },
			{ name: 'Port Allen', slug: 'port-allen-la' },
			{ name: 'Rayne', slug: 'rayne-la' },
			{ name: 'Ruston', slug: 'ruston-la' },
			{ name: 'Shreveport', slug: 'shreveport-la' },
			{ name: 'Slidell', slug: 'slidell-la' },
			{ name: 'Sulphur', slug: 'sulphur-la' },
			{ name: 'Sunset', slug: 'sunset-la' },
			{ name: 'Tangipahoa', slug: 'tangipahoa-la' },
			{ name: 'Thibodaux', slug: 'thibodaux-la' },
			{ name: 'Ville Platte', slug: 'ville-platte-la' },
			{ name: 'Walker', slug: 'walker-la' },
			{ name: 'West Monroe', slug: 'west-monroe-la' },
			{ name: 'Westlake', slug: 'westlake-la' },
			{ name: 'Youngsville', slug: 'youngsville-la' },
			{ name: 'Zachary', slug: 'zachary-la' },
		],
		stateName: 'Louisiana',
	},
	ME: {
		cities: [
			{ name: 'Auburn', slug: 'auburn-me' },
			{ name: 'Augusta', slug: 'augusta-me' },
			{ name: 'Bangor', slug: 'bangor-me' },
			{ name: 'Bath', slug: 'bath-me' },
			{ name: 'Belfast', slug: 'belfast-me' },
			{ name: 'Biddeford', slug: 'biddeford-me' },
			{ name: 'Brewer', slug: 'brewer-me' },
			{ name: 'Calais', slug: 'calais-me' },
			{ name: 'Caribou', slug: 'caribou-me' },
			{ name: 'Eastport', slug: 'eastport-me' },
			{ name: 'Ellsworth', slug: 'ellsworth-me' },
			{ name: 'Gardiner', slug: 'gardiner-me' },
			{ name: 'Hallowell', slug: 'hallowell-me' },
			{ name: 'Lewiston', slug: 'lewiston-me' },
			{ name: 'Old Town', slug: 'old-town-me' },
			{ name: 'Portland', slug: 'portland-me' },
			{ name: 'Presque Isle', slug: 'presque-isle-me' },
			{ name: 'Rockland', slug: 'rockland-me' },
			{ name: 'Saco', slug: 'saco-me' },
			{ name: 'Sanford', slug: 'sanford-me' },
			{ name: 'South Portland', slug: 'south-portland-me' },
			{ name: 'Waterville', slug: 'waterville-me' },
			{ name: 'Westbrook', slug: 'westbrook-me' },
		],
		stateName: 'Maine',
	},
	MD: {
		cities: [
			{ name: 'Aberdeen', slug: 'aberdeen-md' },
			{ name: 'Annapolis', slug: 'annapolis-md' },
			{ name: 'Baltimore', slug: 'baltimore-md' },
			{ name: 'Bel Air', slug: 'bel-air-md' },
			{ name: 'Berlin', slug: 'berlin-md' },
			{ name: 'Bethesda', slug: 'bethesda-md' },
			{ name: 'Bladensburg', slug: 'bladensburg-md' },
			{ name: 'Bowie', slug: 'bowie-md' },
			{ name: 'Brunswick', slug: 'brunswick-md' },
			{ name: 'Cambridge', slug: 'cambridge-md' },
			{ name: 'Capitol Heights', slug: 'capitol-heights-md' },
			{ name: 'Centreville', slug: 'centreville-md' },
			{ name: 'Chesapeake Beach', slug: 'chesapeake-beach-md' },
			{ name: 'Chestertown', slug: 'chestertown-md' },
			{ name: 'College Park', slug: 'college-park-md' },
			{ name: 'Columbia', slug: 'columbia-md' },
			{ name: 'Cumberland', slug: 'cumberland-md' },
			{ name: 'Denton', slug: 'denton-md' },
			{ name: 'District Heights', slug: 'district-heights-md' },
			{ name: 'Dundalk', slug: 'dundalk-md' },
			{ name: 'Easton', slug: 'easton-md' },
			{ name: 'Elkton', slug: 'elkton-md' },
			{ name: 'Emmitsburg', slug: 'emmitsburg-md' },
			{ name: 'Frederick', slug: 'frederick-md' },
			{ name: 'Frostburg', slug: 'frostburg-md' },
			{ name: 'Fruitland', slug: 'fruitland-md' },
			{ name: 'Gaithersburg', slug: 'gaithersburg-md' },
			{ name: 'Germantown', slug: 'germantown-md' },
			{ name: 'Glen Burnie', slug: 'glen-burnie-md' },
			{ name: 'Greenbelt', slug: 'greenbelt-md' },
			{ name: 'Hagerstown', slug: 'hagerstown-md' },
			{ name: 'Hampstead', slug: 'hampstead-md' },
			{ name: 'Havre de Grace', slug: 'havre-de-grace-md' },
			{ name: 'Hyattsville', slug: 'hyattsville-md' },
			{ name: 'La Plata', slug: 'la-plata-md' },
			{ name: 'Laurel', slug: 'laurel-md' },
			{ name: 'Leonardtown', slug: 'leonardtown-md' },
			{ name: 'Manchester', slug: 'manchester-md' },
			{ name: 'Middletown', slug: 'middletown-md' },
			{ name: 'Mount Airy', slug: 'mount-airy-md' },
			{ name: 'Mount Rainier', slug: 'mount-rainier-md' },
			{ name: 'North East', slug: 'north-east-md' },
			{ name: 'Ocean City', slug: 'ocean-city-md' },
			{ name: 'Perryville', slug: 'perryville-md' },
			{ name: 'Pocomoke City', slug: 'pocomoke-city-md' },
			{ name: 'Poolesville', slug: 'poolesville-md' },
			{ name: 'Princess Anne', slug: 'princess-anne-md' },
			{ name: 'Rising Sun', slug: 'rising-sun-md' },
			{ name: 'Rockville', slug: 'rockville-md' },
			{ name: 'Salisbury', slug: 'salisbury-md' },
			{ name: 'Silver Spring', slug: 'silver-spring-md' },
			{ name: 'Sykesville', slug: 'sykesville-md' },
			{ name: 'Takoma Park', slug: 'takoma-park-md' },
			{ name: 'Taneytown', slug: 'taneytown-md' },
			{ name: 'Thurmont', slug: 'thurmont-md' },
			{ name: 'Walkersville', slug: 'walkersville-md' },
			{ name: 'Westernport', slug: 'westernport-md' },
			{ name: 'Westminster', slug: 'westminster-md' },
		],
		stateName: 'Maryland',
	},
	MA: {
		cities: [
			{ name: 'Attleboro', slug: 'attleboro-ma' },
			{ name: 'Beverly', slug: 'beverly-ma' },
			{ name: 'Boston', slug: 'boston-ma' },
			{ name: 'Brockton', slug: 'brockton-ma' },
			{ name: 'Cambridge', slug: 'cambridge-ma' },
			{ name: 'Chelsea', slug: 'chelsea-ma' },
			{ name: 'Chicopee', slug: 'chicopee-ma' },
			{ name: 'Everett', slug: 'everett-ma' },
			{ name: 'Fall River', slug: 'fall-river-ma' },
			{ name: 'Fitchburg', slug: 'fitchburg-ma' },
			{ name: 'Gardner', slug: 'gardner-ma' },
			{ name: 'Gloucester', slug: 'gloucester-ma' },
			{ name: 'Haverhill', slug: 'haverhill-ma' },
			{ name: 'Holyoke', slug: 'holyoke-ma' },
			{ name: 'Lawrence', slug: 'lawrence-ma' },
			{ name: 'Leominster', slug: 'leominster-ma' },
			{ name: 'Lowell', slug: 'lowell-ma' },
			{ name: 'Lynn', slug: 'lynn-ma' },
			{ name: 'Malden', slug: 'malden-ma' },
			{ name: 'Marlborough', slug: 'marlborough-ma' },
			{ name: 'Medford', slug: 'medford-ma' },
			{ name: 'Melrose', slug: 'melrose-ma' },
			{ name: 'Methuen', slug: 'methuen-ma' },
			{ name: 'New Bedford', slug: 'new-bedford-ma' },
			{ name: 'Newburyport', slug: 'newburyport-ma' },
			{ name: 'Newton', slug: 'newton-ma' },
			{ name: 'North Adams', slug: 'north-adams-ma' },
			{ name: 'Northampton', slug: 'northampton-ma' },
			{ name: 'Peabody', slug: 'peabody-ma' },
			{ name: 'Pittsfield', slug: 'pittsfield-ma' },
			{ name: 'Quincy', slug: 'quincy-ma' },
			{ name: 'Revere', slug: 'revere-ma' },
			{ name: 'Salem', slug: 'salem-ma' },
			{ name: 'Somerville', slug: 'somerville-ma' },
			{ name: 'Springfield', slug: 'springfield-ma' },
			{ name: 'Taunton', slug: 'taunton-ma' },
			{ name: 'Waltham', slug: 'waltham-ma' },
			{ name: 'Watertown', slug: 'watertown-ma' },
			{ name: 'Westfield', slug: 'westfield-ma' },
			{ name: 'Woburn', slug: 'woburn-ma' },
			{ name: 'Worcester', slug: 'worcester-ma' },
		],
		stateName: 'Massachusetts',
	},
	MI: {
		cities: [
			{ name: 'Adrian', slug: 'adrian-mi' },
			{ name: 'Allegan', slug: 'allegan-mi' },
			{ name: 'Ann Arbor', slug: 'ann-arbor-mi' },
			{ name: 'Auburn Hills', slug: 'auburn-hills-mi' },
			{ name: 'Battle Creek', slug: 'battle-creek-mi' },
			{ name: 'Bay City', slug: 'bay-city-mi' },
			{ name: 'Birmingham', slug: 'birmingham-mi' },
			{ name: 'Clinton', slug: 'clinton-mi' },
			{ name: 'Dearborn Heights', slug: 'dearborn-heights-mi' },
			{ name: 'Dearborn', slug: 'dearborn-mi' },
			{ name: 'Detroit', slug: 'detroit-mi' },
			{ name: 'East Lansing', slug: 'east-lansing-mi' },
			{ name: 'Farmington Hills', slug: 'farmington-hills-mi' },
			{ name: 'Fenton', slug: 'fenton-mi' },
			{ name: 'Ferndale', slug: 'ferndale-mi' },
			{ name: 'Flint', slug: 'flint-mi' },
			{ name: 'Genesee', slug: 'genesee-mi' },
			{ name: 'Grand Haven', slug: 'grand-haven-mi' },
			{ name: 'Grand Rapids', slug: 'grand-rapids-mi' },
			{ name: 'Holland', slug: 'holland-mi' },
			{ name: 'Jackson', slug: 'jackson-mi' },
			{ name: 'Kalamazoo', slug: 'kalamazoo-mi' },
			{ name: 'Lansing', slug: 'lansing-mi' },
			{ name: 'Lapeer', slug: 'lapeer-mi' },
			{ name: 'Lincoln Park', slug: 'lincoln-park-mi' },
			{ name: 'Livonia', slug: 'livonia-mi' },
			{ name: 'Madison Heights', slug: 'madison-heights-mi' },
			{ name: 'Midland', slug: 'midland-mi' },
			{ name: 'Monroe', slug: 'monroe-mi' },
			{ name: 'Mount Pleasant', slug: 'mount-pleasant-mi' },
			{ name: 'Muskegon', slug: 'muskegon-mi' },
			{ name: 'New Baltimore', slug: 'new-baltimore-mi' },
			{ name: 'Niles', slug: 'niles-mi' },
			{ name: 'Novi', slug: 'novi-mi' },
			{ name: 'Oak Park', slug: 'oak-park-mi' },
			{ name: 'Pontiac', slug: 'pontiac-mi' },
			{ name: 'Port Huron', slug: 'port-huron-mi' },
			{ name: 'Portage', slug: 'portage-mi' },
			{ name: 'Rochester Hills', slug: 'rochester-hills-mi' },
			{ name: 'Romulus', slug: 'romulus-mi' },
			{ name: 'Roseville', slug: 'roseville-mi' },
			{ name: 'Royal Oak', slug: 'royal-oak-mi' },
			{ name: 'Saginaw', slug: 'saginaw-mi' },
			{ name: 'Sault Sainte Marie', slug: 'sault-sainte-marie-mi' },
			{ name: 'South Lyon', slug: 'south-lyon-mi' },
			{ name: 'Southfield', slug: 'southfield-mi' },
			{ name: 'Southgate', slug: 'southgate-mi' },
			{ name: 'Sterling Heights', slug: 'sterling-heights-mi' },
			{ name: 'Taylor', slug: 'taylor-mi' },
			{ name: 'Traverse City', slug: 'traverse-city-mi' },
			{ name: 'Trenton', slug: 'trenton-mi' },
			{ name: 'Troy', slug: 'troy-mi' },
			{ name: 'Warren', slug: 'warren-mi' },
			{ name: 'Waterford', slug: 'waterford-mi' },
			{ name: 'Westland', slug: 'westland-mi' },
			{ name: 'Wixom', slug: 'wixom-mi' },
			{ name: 'Wyandotte', slug: 'wyandotte-mi' },
			{ name: 'Wyoming', slug: 'wyoming-mi' },
		],
		stateName: 'Michigan',
	},
	MN: {
		cities: [
			{ name: 'Alexandria', slug: 'alexandria-mn' },
			{ name: 'Andover', slug: 'andover-mn' },
			{ name: 'Apple Valley', slug: 'apple-valley-mn' },
			{ name: 'Austin', slug: 'austin-mn' },
			{ name: 'Beltrami', slug: 'beltrami-mn' },
			{ name: 'Bemidji', slug: 'bemidji-mn' },
			{ name: 'Big Lake', slug: 'big-lake-mn' },
			{ name: 'Blaine', slug: 'blaine-mn' },
			{ name: 'Bloomington', slug: 'bloomington-mn' },
			{ name: 'Blue Earth', slug: 'blue-earth-mn' },
			{ name: 'Brainerd', slug: 'brainerd-mn' },
			{ name: 'Brooklyn Park', slug: 'brooklyn-park-mn' },
			{ name: 'Burnsville', slug: 'burnsville-mn' },
			{ name: 'Carlton', slug: 'carlton-mn' },
			{ name: 'Carver', slug: 'carver-mn' },
			{ name: 'Champlin', slug: 'champlin-mn' },
			{ name: 'Chanhassen', slug: 'chanhassen-mn' },
			{ name: 'Chaska', slug: 'chaska-mn' },
			{ name: 'Cloquet', slug: 'cloquet-mn' },
			{ name: 'Coon Rapids', slug: 'coon-rapids-mn' },
			{ name: 'Cottage Grove', slug: 'cottage-grove-mn' },
			{ name: 'Duluth', slug: 'duluth-mn' },
			{ name: 'Eagan', slug: 'eagan-mn' },
			{ name: 'Eden Prairie', slug: 'eden-prairie-mn' },
			{ name: 'Edina', slug: 'edina-mn' },
			{ name: 'Elk River', slug: 'elk-river-mn' },
			{ name: 'Farmington', slug: 'farmington-mn' },
			{ name: 'Forest Lake', slug: 'forest-lake-mn' },
			{ name: 'Goodhue', slug: 'goodhue-mn' },
			{ name: 'Hastings', slug: 'hastings-mn' },
			{ name: 'Hutchinson', slug: 'hutchinson-mn' },
			{ name: 'Inver Grove Heights', slug: 'inver-grove-heights-mn' },
			{ name: 'Lakeville', slug: 'lakeville-mn' },
			{ name: 'Mankato', slug: 'mankato-mn' },
			{ name: 'Maple Grove', slug: 'maple-grove-mn' },
			{ name: 'Minneapolis', slug: 'minneapolis-mn' },
			{ name: 'Minnetonka', slug: 'minnetonka-mn' },
			{ name: 'Moorhead', slug: 'moorhead-mn' },
			{ name: 'New Ulm', slug: 'new-ulm-mn' },
			{ name: 'Nicollet', slug: 'nicollet-mn' },
			{ name: 'Owatonna', slug: 'owatonna-mn' },
			{ name: 'Plymouth', slug: 'plymouth-mn' },
			{ name: 'Prior Lake', slug: 'prior-lake-mn' },
			{ name: 'Rochester', slug: 'rochester-mn' },
			{ name: 'Saint Cloud', slug: 'saint-cloud-mn' },
			{ name: 'Saint Michael', slug: 'saint-michael-mn' },
			{ name: 'Saint Paul', slug: 'saint-paul-mn' },
			{ name: 'Savage', slug: 'savage-mn' },
			{ name: 'Shakopee', slug: 'shakopee-mn' },
			{ name: 'Sherburn', slug: 'sherburn-mn' },
			{ name: 'Stillwater', slug: 'stillwater-mn' },
			{ name: 'Twin Cities', slug: 'twin-cities-mn' },
			{ name: 'Waconia', slug: 'waconia-mn' },
			{ name: 'White Bear Lake', slug: 'white-bear-lake-mn' },
			{ name: 'Willmar', slug: 'willmar-mn' },
			{ name: 'Winona', slug: 'winona-mn' },
			{ name: 'Woodbury', slug: 'woodbury-mn' },
			{ name: 'Wright', slug: 'wright-mn' },
		],
		stateName: 'Minnesota',
	},
	MS: {
		cities: [
			{ name: 'Amory', slug: 'amory-ms' },
			{ name: 'Batesville', slug: 'batesville-ms' },
			{ name: 'Bay Saint Louis', slug: 'bay-saint-louis-ms' },
			{ name: 'Biloxi', slug: 'biloxi-ms' },
			{ name: 'Booneville', slug: 'booneville-ms' },
			{ name: 'Brandon', slug: 'brandon-ms' },
			{ name: 'Brookhaven', slug: 'brookhaven-ms' },
			{ name: 'Byram', slug: 'byram-ms' },
			{ name: 'Canton', slug: 'canton-ms' },
			{ name: 'Clarksdale', slug: 'clarksdale-ms' },
			{ name: 'Cleveland', slug: 'cleveland-ms' },
			{ name: 'Clinton', slug: 'clinton-ms' },
			{ name: 'Columbia', slug: 'columbia-ms' },
			{ name: 'Columbus', slug: 'columbus-ms' },
			{ name: 'Corinth', slug: 'corinth-ms' },
			{ name: 'Diamondhead', slug: 'diamondhead-ms' },
			{ name: 'Florence', slug: 'florence-ms' },
			{ name: 'Flowood', slug: 'flowood-ms' },
			{ name: 'Gautier', slug: 'gautier-ms' },
			{ name: 'Greenville', slug: 'greenville-ms' },
			{ name: 'Greenwood', slug: 'greenwood-ms' },
			{ name: 'Grenada', slug: 'grenada-ms' },
			{ name: 'Gulfport', slug: 'gulfport-ms' },
			{ name: 'Hattiesburg', slug: 'hattiesburg-ms' },
			{ name: 'Hernando', slug: 'hernando-ms' },
			{ name: 'Holly Springs', slug: 'holly-springs-ms' },
			{ name: 'Horn Lake', slug: 'horn-lake-ms' },
			{ name: 'Indianola', slug: 'indianola-ms' },
			{ name: 'Jackson', slug: 'jackson-ms' },
			{ name: 'Kosciusko', slug: 'kosciusko-ms' },
			{ name: 'Lauderdale', slug: 'lauderdale-ms' },
			{ name: 'Laurel', slug: 'laurel-ms' },
			{ name: 'Long Beach', slug: 'long-beach-ms' },
			{ name: 'Madison', slug: 'madison-ms' },
			{ name: 'McComb', slug: 'mccomb-ms' },
			{ name: 'Meridian', slug: 'meridian-ms' },
			{ name: 'Moss Point', slug: 'moss-point-ms' },
			{ name: 'Natchez', slug: 'natchez-ms' },
			{ name: 'New Albany', slug: 'new-albany-ms' },
			{ name: 'Ocean Springs', slug: 'ocean-springs-ms' },
			{ name: 'Olive Branch', slug: 'olive-branch-ms' },
			{ name: 'Oxford', slug: 'oxford-ms' },
			{ name: 'Pascagoula', slug: 'pascagoula-ms' },
			{ name: 'Pass Christian', slug: 'pass-christian-ms' },
			{ name: 'Pearl', slug: 'pearl-ms' },
			{ name: 'Petal', slug: 'petal-ms' },
			{ name: 'Philadelphia', slug: 'philadelphia-ms' },
			{ name: 'Picayune', slug: 'picayune-ms' },
			{ name: 'Richland', slug: 'richland-ms' },
			{ name: 'Ridgeland', slug: 'ridgeland-ms' },
			{ name: 'Senatobia', slug: 'senatobia-ms' },
			{ name: 'Southaven', slug: 'southaven-ms' },
			{ name: 'Starkville', slug: 'starkville-ms' },
			{ name: 'Tupelo', slug: 'tupelo-ms' },
			{ name: 'Vicksburg', slug: 'vicksburg-ms' },
			{ name: 'Waveland', slug: 'waveland-ms' },
			{ name: 'West Point', slug: 'west-point-ms' },
			{ name: 'Yazoo City', slug: 'yazoo-city-ms' },
		],
		stateName: 'Mississippi',
	},
	MO: {
		cities: [
			{ name: 'Arnold', slug: 'arnold-mo' },
			{ name: 'Ballwin', slug: 'ballwin-mo' },
			{ name: 'Belton', slug: 'belton-mo' },
			{ name: 'Blue Springs', slug: 'blue-springs-mo' },
			{ name: 'Bolivar', slug: 'bolivar-mo' },
			{ name: 'Branson', slug: 'branson-mo' },
			{ name: 'Bridgeton', slug: 'bridgeton-mo' },
			{ name: 'Cape Girardeau', slug: 'cape-girardeau-mo' },
			{ name: 'Carthage', slug: 'carthage-mo' },
			{ name: 'Chesterfield', slug: 'chesterfield-mo' },
			{ name: 'Clayton', slug: 'clayton-mo' },
			{ name: 'Columbia', slug: 'columbia-mo' },
			{ name: 'Eureka', slug: 'eureka-mo' },
			{ name: 'Farmington', slug: 'farmington-mo' },
			{ name: 'Ferguson', slug: 'ferguson-mo' },
			{ name: 'Festus', slug: 'festus-mo' },
			{ name: 'Florissant', slug: 'florissant-mo' },
			{ name: 'Grain Valley', slug: 'grain-valley-mo' },
			{ name: 'Grandview', slug: 'grandview-mo' },
			{ name: 'Hannibal', slug: 'hannibal-mo' },
			{ name: 'Hazelwood', slug: 'hazelwood-mo' },
			{ name: 'Independence', slug: 'independence-mo' },
			{ name: 'Jackson', slug: 'jackson-mo' },
			{ name: 'Jasper', slug: 'jasper-mo' },
			{ name: 'Jefferson City', slug: 'jefferson-city-mo' },
			{ name: 'Joplin', slug: 'joplin-mo' },
			{ name: 'Kansas City', slug: 'kansas-city-mo' },
			{ name: 'Kearney', slug: 'kearney-mo' },
			{ name: 'Kirksville', slug: 'kirksville-mo' },
			{ name: 'Kirkwood', slug: 'kirkwood-mo' },
			{ name: 'Laclede', slug: 'laclede-mo' },
			{ name: 'Lebanon', slug: 'lebanon-mo' },
			{ name: 'Liberty', slug: 'liberty-mo' },
			{ name: 'Macon', slug: 'macon-mo' },
			{ name: 'Marshall', slug: 'marshall-mo' },
			{ name: 'Maryland Heights', slug: 'maryland-heights-mo' },
			{ name: 'Maryville', slug: 'maryville-mo' },
			{ name: 'Moberly', slug: 'moberly-mo' },
			{ name: 'New Madrid', slug: 'new-madrid-mo' },
			{ name: 'Nixa', slug: 'nixa-mo' },
			{ name: "O'Fallon", slug: "o'fallon-mo" },
			{ name: 'Ozark', slug: 'ozark-mo' },
			{ name: 'Perry', slug: 'perry-mo' },
			{ name: 'Polk', slug: 'polk-mo' },
			{ name: 'Poplar Bluff', slug: 'poplar-bluff-mo' },
			{ name: 'Raymore', slug: 'raymore-mo' },
			{ name: 'Republic', slug: 'republic-mo' },
			{ name: 'Rolla', slug: 'rolla-mo' },
			{ name: 'Saint Charles', slug: 'saint-charles-mo' },
			{ name: 'Saint Joseph', slug: 'saint-joseph-mo' },
			{ name: 'Sedalia', slug: 'sedalia-mo' },
			{ name: 'Springfield', slug: 'springfield-mo' },
			{ name: 'Town and Country', slug: 'town-and-country-mo' },
			{ name: 'Troy', slug: 'troy-mo' },
			{ name: 'Union', slug: 'union-mo' },
			{ name: 'Warrensburg', slug: 'warrensburg-mo' },
			{ name: 'Washington', slug: 'washington-mo' },
			{ name: 'Wentzville', slug: 'wentzville-mo' },
		],
		stateName: 'Missouri',
	},
	MT: {
		cities: [
			{ name: 'Baker', slug: 'baker-mt' },
			{ name: 'Belgrade', slug: 'belgrade-mt' },
			{ name: 'Big Timber', slug: 'big-timber-mt' },
			{ name: 'Billings', slug: 'billings-mt' },
			{ name: 'Boulder', slug: 'boulder-mt' },
			{ name: 'Bozeman', slug: 'bozeman-mt' },
			{ name: 'Browning', slug: 'browning-mt' },
			{ name: 'Butte', slug: 'butte-mt' },
			{ name: 'Chinook', slug: 'chinook-mt' },
			{ name: 'Choteau', slug: 'choteau-mt' },
			{ name: 'Colstrip', slug: 'colstrip-mt' },
			{ name: 'Columbia Falls', slug: 'columbia-falls-mt' },
			{ name: 'Columbus', slug: 'columbus-mt' },
			{ name: 'Conrad', slug: 'conrad-mt' },
			{ name: 'Cut Bank', slug: 'cut-bank-mt' },
			{ name: 'Deer Lodge', slug: 'deer-lodge-mt' },
			{ name: 'Dillon', slug: 'dillon-mt' },
			{ name: 'East Helena', slug: 'east-helena-mt' },
			{ name: 'Eureka', slug: 'eureka-mt' },
			{ name: 'Fairview', slug: 'fairview-mt' },
			{ name: 'Forsyth', slug: 'forsyth-mt' },
			{ name: 'Fort Benton', slug: 'fort-benton-mt' },
			{ name: 'Glasgow', slug: 'glasgow-mt' },
			{ name: 'Glendive', slug: 'glendive-mt' },
			{ name: 'Great Falls', slug: 'great-falls-mt' },
			{ name: 'Hamilton', slug: 'hamilton-mt' },
			{ name: 'Hardin', slug: 'hardin-mt' },
			{ name: 'Harlowton', slug: 'harlowton-mt' },
			{ name: 'Havre', slug: 'havre-mt' },
			{ name: 'Helena', slug: 'helena-mt' },
			{ name: 'Kalispell', slug: 'kalispell-mt' },
			{ name: 'Laurel', slug: 'laurel-mt' },
			{ name: 'Lewistown', slug: 'lewistown-mt' },
			{ name: 'Libby', slug: 'libby-mt' },
			{ name: 'Livingston', slug: 'livingston-mt' },
			{ name: 'Malta', slug: 'malta-mt' },
			{ name: 'Manhattan', slug: 'manhattan-mt' },
			{ name: 'Miles City', slug: 'miles-city-mt' },
			{ name: 'Missoula', slug: 'missoula-mt' },
			{ name: 'Pinesdale', slug: 'pinesdale-mt' },
			{ name: 'Plains', slug: 'plains-mt' },
			{ name: 'Plentywood', slug: 'plentywood-mt' },
			{ name: 'Polson', slug: 'polson-mt' },
			{ name: 'Poplar', slug: 'poplar-mt' },
			{ name: 'Red Lodge', slug: 'red-lodge-mt' },
			{ name: 'Ronan', slug: 'ronan-mt' },
			{ name: 'Roundup', slug: 'roundup-mt' },
			{ name: 'Scobey', slug: 'scobey-mt' },
			{ name: 'Shelby', slug: 'shelby-mt' },
			{ name: 'Sidney', slug: 'sidney-mt' },
			{ name: 'Stevensville', slug: 'stevensville-mt' },
			{ name: 'Thompson Falls', slug: 'thompson-falls-mt' },
			{ name: 'Three Forks', slug: 'three-forks-mt' },
			{ name: 'Townsend', slug: 'townsend-mt' },
			{ name: 'West Yellowstone', slug: 'west-yellowstone-mt' },
			{ name: 'Whitefish', slug: 'whitefish-mt' },
			{ name: 'Whitehall', slug: 'whitehall-mt' },
			{ name: 'Wolf Point', slug: 'wolf-point-mt' },
		],
		stateName: 'Montana',
	},
	NE: {
		cities: [
			{ name: 'Alliance', slug: 'alliance-ne' },
			{ name: 'Ashland', slug: 'ashland-ne' },
			{ name: 'Auburn', slug: 'auburn-ne' },
			{ name: 'Aurora', slug: 'aurora-ne' },
			{ name: 'Beatrice', slug: 'beatrice-ne' },
			{ name: 'Bellevue', slug: 'bellevue-ne' },
			{ name: 'Blair', slug: 'blair-ne' },
			{ name: 'Broken Bow', slug: 'broken-bow-ne' },
			{ name: 'Central City', slug: 'central-city-ne' },
			{ name: 'Chadron', slug: 'chadron-ne' },
			{ name: 'Columbus', slug: 'columbus-ne' },
			{ name: 'Cozad', slug: 'cozad-ne' },
			{ name: 'Crete', slug: 'crete-ne' },
			{ name: 'David City', slug: 'david-city-ne' },
			{ name: 'Fairbury', slug: 'fairbury-ne' },
			{ name: 'Falls City', slug: 'falls-city-ne' },
			{ name: 'Fremont', slug: 'fremont-ne' },
			{ name: 'Geneva', slug: 'geneva-ne' },
			{ name: 'Gering', slug: 'gering-ne' },
			{ name: 'Gothenburg', slug: 'gothenburg-ne' },
			{ name: 'Grand Island', slug: 'grand-island-ne' },
			{ name: 'Gretna', slug: 'gretna-ne' },
			{ name: 'Hastings', slug: 'hastings-ne' },
			{ name: 'Hickman', slug: 'hickman-ne' },
			{ name: 'Holdrege', slug: 'holdrege-ne' },
			{ name: 'Imperial', slug: 'imperial-ne' },
			{ name: 'Kearney', slug: 'kearney-ne' },
			{ name: 'Kimball', slug: 'kimball-ne' },
			{ name: 'La Vista', slug: 'la-vista-ne' },
			{ name: 'Lexington', slug: 'lexington-ne' },
			{ name: 'Lincoln', slug: 'lincoln-ne' },
			{ name: 'Madison', slug: 'madison-ne' },
			{ name: 'McCook', slug: 'mccook-ne' },
			{ name: 'Milford', slug: 'milford-ne' },
			{ name: 'Minden', slug: 'minden-ne' },
			{ name: 'Nebraska City', slug: 'nebraska-city-ne' },
			{ name: 'Norfolk', slug: 'norfolk-ne' },
			{ name: 'North Platte', slug: 'north-platte-ne' },
			{ name: "O'Neill", slug: "o'neill-ne" },
			{ name: 'Ogallala', slug: 'ogallala-ne' },
			{ name: 'Omaha', slug: 'omaha-ne' },
			{ name: 'Ord', slug: 'ord-ne' },
			{ name: 'Papillion', slug: 'papillion-ne' },
			{ name: 'Plattsmouth', slug: 'plattsmouth-ne' },
			{ name: 'Saint Paul', slug: 'saint-paul-ne' },
			{ name: 'Schuyler', slug: 'schuyler-ne' },
			{ name: 'Scottsbluff', slug: 'scottsbluff-ne' },
			{ name: 'Seward', slug: 'seward-ne' },
			{ name: 'Sidney', slug: 'sidney-ne' },
			{ name: 'South Sioux City', slug: 'south-sioux-city-ne' },
			{ name: 'Syracuse', slug: 'syracuse-ne' },
			{ name: 'Valentine', slug: 'valentine-ne' },
			{ name: 'Valley', slug: 'valley-ne' },
			{ name: 'Wahoo', slug: 'wahoo-ne' },
			{ name: 'Waverly', slug: 'waverly-ne' },
			{ name: 'Wayne', slug: 'wayne-ne' },
			{ name: 'West Point', slug: 'west-point-ne' },
			{ name: 'York', slug: 'york-ne' },
		],
		stateName: 'Nebraska',
	},
	NV: {
		cities: [
			{ name: 'Boulder City', slug: 'boulder-city-nv' },
			{ name: 'Caliente', slug: 'caliente-nv' },
			{ name: 'Carlin', slug: 'carlin-nv' },
			{ name: 'Carson City', slug: 'carson-city-nv' },
			{ name: 'Elko', slug: 'elko-nv' },
			{ name: 'Ely', slug: 'ely-nv' },
			{ name: 'Fallon', slug: 'fallon-nv' },
			{ name: 'Fernley', slug: 'fernley-nv' },
			{ name: 'Henderson', slug: 'henderson-nv' },
			{ name: 'Las Vegas', slug: 'las-vegas-nv' },
			{ name: 'Lovelock', slug: 'lovelock-nv' },
			{ name: 'Mesquite', slug: 'mesquite-nv' },
			{ name: 'North Las Vegas', slug: 'north-las-vegas-nv' },
			{ name: 'Reno', slug: 'reno-nv' },
			{ name: 'Sparks', slug: 'sparks-nv' },
			{ name: 'Wells', slug: 'wells-nv' },
			{ name: 'West Wendover', slug: 'west-wendover-nv' },
			{ name: 'Winnemucca', slug: 'winnemucca-nv' },
			{ name: 'Yerington', slug: 'yerington-nv' },
		],
		stateName: 'Nevada',
	},
	NH: {
		cities: [
			{ name: 'Berlin', slug: 'berlin-nh' },
			{ name: 'Claremont', slug: 'claremont-nh' },
			{ name: 'Concord', slug: 'concord-nh' },
			{ name: 'Dover', slug: 'dover-nh' },
			{ name: 'Franklin', slug: 'franklin-nh' },
			{ name: 'Keene', slug: 'keene-nh' },
			{ name: 'Laconia', slug: 'laconia-nh' },
			{ name: 'Lebanon', slug: 'lebanon-nh' },
			{ name: 'Manchester', slug: 'manchester-nh' },
			{ name: 'Nashua', slug: 'nashua-nh' },
			{ name: 'Portsmouth', slug: 'portsmouth-nh' },
			{ name: 'Rochester', slug: 'rochester-nh' },
			{ name: 'Somersworth', slug: 'somersworth-nh' },
		],
		stateName: 'New Hampshire',
	},
	NJ: {
		cities: [
			{ name: 'Asbury Park', slug: 'asbury-park-nj' },
			{ name: 'Atlantic City', slug: 'atlantic-city-nj' },
			{ name: 'Bayonne', slug: 'bayonne-nj' },
			{ name: 'Bellmawr', slug: 'bellmawr-nj' },
			{ name: 'Bergenfield', slug: 'bergenfield-nj' },
			{ name: 'Camden', slug: 'camden-nj' },
			{ name: 'Cliffside Park', slug: 'cliffside-park-nj' },
			{ name: 'Clifton', slug: 'clifton-nj' },
			{ name: 'Collingswood', slug: 'collingswood-nj' },
			{ name: 'East Orange', slug: 'east-orange-nj' },
			{ name: 'Eatontown', slug: 'eatontown-nj' },
			{ name: 'Elizabeth', slug: 'elizabeth-nj' },
			{ name: 'Englewood', slug: 'englewood-nj' },
			{ name: 'Fair Lawn', slug: 'fair-lawn-nj' },
			{ name: 'Florham Park', slug: 'florham-park-nj' },
			{ name: 'Fort Lee', slug: 'fort-lee-nj' },
			{ name: 'Franklin', slug: 'franklin-nj' },
			{ name: 'Garfield', slug: 'garfield-nj' },
			{ name: 'Glassboro', slug: 'glassboro-nj' },
			{ name: 'Hackensack', slug: 'hackensack-nj' },
			{ name: 'Hammonton', slug: 'hammonton-nj' },
			{ name: 'Hawthorne', slug: 'hawthorne-nj' },
			{ name: 'Hoboken', slug: 'hoboken-nj' },
			{ name: 'Hopatcong', slug: 'hopatcong-nj' },
			{ name: 'Jersey City', slug: 'jersey-city-nj' },
			{ name: 'Kearny', slug: 'kearny-nj' },
			{ name: 'Linden', slug: 'linden-nj' },
			{ name: 'Long Branch', slug: 'long-branch-nj' },
			{ name: 'Madison', slug: 'madison-nj' },
			{ name: 'Morristown', slug: 'morristown-nj' },
			{ name: 'New Brunswick', slug: 'new-brunswick-nj' },
			{ name: 'New Milford', slug: 'new-milford-nj' },
			{ name: 'Newark', slug: 'newark-nj' },
			{ name: 'Oakland', slug: 'oakland-nj' },
			{ name: 'Ocean City', slug: 'ocean-city-nj' },
			{ name: 'Paramus', slug: 'paramus-nj' },
			{ name: 'Passaic', slug: 'passaic-nj' },
			{ name: 'Paterson', slug: 'paterson-nj' },
			{ name: 'Phillipsburg', slug: 'phillipsburg-nj' },
			{ name: 'Princeton', slug: 'princeton-nj' },
			{ name: 'Rahway', slug: 'rahway-nj' },
			{ name: 'Ramsey', slug: 'ramsey-nj' },
			{ name: 'Red Bank', slug: 'red-bank-nj' },
			{ name: 'Ridgefield Park', slug: 'ridgefield-park-nj' },
			{ name: 'Ridgewood', slug: 'ridgewood-nj' },
			{ name: 'Ringwood', slug: 'ringwood-nj' },
			{ name: 'Rutherford', slug: 'rutherford-nj' },
			{ name: 'Sayreville', slug: 'sayreville-nj' },
			{ name: 'Secaucus', slug: 'secaucus-nj' },
			{ name: 'Somerset', slug: 'somerset-nj' },
			{ name: 'South Plainfield', slug: 'south-plainfield-nj' },
			{ name: 'Summit', slug: 'summit-nj' },
			{ name: 'Trenton', slug: 'trenton-nj' },
			{ name: 'Union City', slug: 'union-city-nj' },
			{ name: 'Vineland', slug: 'vineland-nj' },
			{ name: 'West New York', slug: 'west-new-york-nj' },
			{ name: 'Westfield', slug: 'westfield-nj' },
			{ name: 'Westwood', slug: 'westwood-nj' },
		],
		stateName: 'New Jersey',
	},
	NM: {
		cities: [
			{ name: 'Alamogordo', slug: 'alamogordo-nm' },
			{ name: 'Albuquerque', slug: 'albuquerque-nm' },
			{ name: 'Anthony', slug: 'anthony-nm' },
			{ name: 'Artesia', slug: 'artesia-nm' },
			{ name: 'Aztec', slug: 'aztec-nm' },
			{ name: 'Bayard', slug: 'bayard-nm' },
			{ name: 'Belen', slug: 'belen-nm' },
			{ name: 'Bernalillo', slug: 'bernalillo-nm' },
			{ name: 'Bloomfield', slug: 'bloomfield-nm' },
			{ name: 'Bosque Farms', slug: 'bosque-farms-nm' },
			{ name: 'Capitan', slug: 'capitan-nm' },
			{ name: 'Carlsbad', slug: 'carlsbad-nm' },
			{ name: 'Clayton', slug: 'clayton-nm' },
			{ name: 'Clovis', slug: 'clovis-nm' },
			{ name: 'Columbus', slug: 'columbus-nm' },
			{ name: 'Corrales', slug: 'corrales-nm' },
			{ name: 'Deming', slug: 'deming-nm' },
			{ name: 'Dexter', slug: 'dexter-nm' },
			{ name: 'Elephant Butte', slug: 'elephant-butte-nm' },
			{ name: 'Espanola', slug: 'espanola-nm' },
			{ name: 'Estancia', slug: 'estancia-nm' },
			{ name: 'Eunice', slug: 'eunice-nm' },
			{ name: 'Farmington', slug: 'farmington-nm' },
			{ name: 'Gallup', slug: 'gallup-nm' },
			{ name: 'Grants', slug: 'grants-nm' },
			{ name: 'Hatch', slug: 'hatch-nm' },
			{ name: 'Hobbs', slug: 'hobbs-nm' },
			{ name: 'Jal', slug: 'jal-nm' },
			{ name: 'Las Cruces', slug: 'las-cruces-nm' },
			{ name: 'Las Vegas', slug: 'las-vegas-nm' },
			{ name: 'Lordsburg', slug: 'lordsburg-nm' },
			{ name: 'Los Lunas', slug: 'los-lunas-nm' },
			{ name: 'Loving', slug: 'loving-nm' },
			{ name: 'Lovington', slug: 'lovington-nm' },
			{ name: 'Luna', slug: 'luna-nm' },
			{ name: 'Mesilla', slug: 'mesilla-nm' },
			{ name: 'Milan', slug: 'milan-nm' },
			{ name: 'Moriarty', slug: 'moriarty-nm' },
			{ name: 'Pecos', slug: 'pecos-nm' },
			{ name: 'Peralta', slug: 'peralta-nm' },
			{ name: 'Portales', slug: 'portales-nm' },
			{ name: 'Questa', slug: 'questa-nm' },
			{ name: 'Raton', slug: 'raton-nm' },
			{ name: 'Rio Rancho', slug: 'rio-rancho-nm' },
			{ name: 'Roswell', slug: 'roswell-nm' },
			{ name: 'Ruidoso Downs', slug: 'ruidoso-downs-nm' },
			{ name: 'Ruidoso', slug: 'ruidoso-nm' },
			{ name: 'San Miguel', slug: 'san-miguel-nm' },
			{ name: 'Santa Clara', slug: 'santa-clara-nm' },
			{ name: 'Santa Fe', slug: 'santa-fe-nm' },
			{ name: 'Santa Rosa', slug: 'santa-rosa-nm' },
			{ name: 'Silver City', slug: 'silver-city-nm' },
			{ name: 'Socorro', slug: 'socorro-nm' },
			{ name: 'Sunland Park', slug: 'sunland-park-nm' },
			{ name: 'Taos', slug: 'taos-nm' },
			{ name: 'Truth or Consequences', slug: 'truth-or-consequences-nm' },
			{ name: 'Tucumcari', slug: 'tucumcari-nm' },
			{ name: 'Tularosa', slug: 'tularosa-nm' },
		],
		stateName: 'New Mexico',
	},
	NY: {
		cities: [
			{ name: 'Albany', slug: 'albany-ny' },
			{ name: 'Auburn', slug: 'auburn-ny' },
			{ name: 'Beacon', slug: 'beacon-ny' },
			{ name: 'Binghamton', slug: 'binghamton-ny' },
			{ name: 'Buffalo', slug: 'buffalo-ny' },
			{ name: 'Canandaigua', slug: 'canandaigua-ny' },
			{ name: 'Dobbs Ferry', slug: 'dobbs-ferry-ny' },
			{ name: 'Elmira', slug: 'elmira-ny' },
			{ name: 'Endicott', slug: 'endicott-ny' },
			{ name: 'Freeport', slug: 'freeport-ny' },
			{ name: 'Garden City', slug: 'garden-city-ny' },
			{ name: 'Glen Cove', slug: 'glen-cove-ny' },
			{ name: 'Gloversville', slug: 'gloversville-ny' },
			{ name: 'Harrison', slug: 'harrison-ny' },
			{ name: 'Hempstead', slug: 'hempstead-ny' },
			{ name: 'Ithaca', slug: 'ithaca-ny' },
			{ name: 'Jamestown', slug: 'jamestown-ny' },
			{ name: 'Johnson City', slug: 'johnson-city-ny' },
			{ name: 'Kingston', slug: 'kingston-ny' },
			{ name: 'Lindenhurst', slug: 'lindenhurst-ny' },
			{ name: 'Lockport', slug: 'lockport-ny' },
			{ name: 'Long Beach', slug: 'long-beach-ny' },
			{ name: 'Long Island', slug: 'long-island-ny' },
			{ name: 'Lynbrook', slug: 'lynbrook-ny' },
			{ name: 'Mamaroneck', slug: 'mamaroneck-ny' },
			{ name: 'Middletown', slug: 'middletown-ny' },
			{ name: 'Mineola', slug: 'mineola-ny' },
			{ name: 'Mount Kisco', slug: 'mount-kisco-ny' },
			{ name: 'Mount Vernon', slug: 'mount-vernon-ny' },
			{ name: 'New Rochelle', slug: 'new-rochelle-ny' },
			{ name: 'New York', slug: 'new-york-ny' },
			{ name: 'Newburgh', slug: 'newburgh-ny' },
			{ name: 'Niagara Falls', slug: 'niagara-falls-ny' },
			{ name: 'North Tonawanda', slug: 'north-tonawanda-ny' },
			{ name: 'Patchogue', slug: 'patchogue-ny' },
			{ name: 'Peekskill', slug: 'peekskill-ny' },
			{ name: 'Plattsburgh', slug: 'plattsburgh-ny' },
			{ name: 'Port Chester', slug: 'port-chester-ny' },
			{ name: 'Poughkeepsie', slug: 'poughkeepsie-ny' },
			{ name: 'Rochester', slug: 'rochester-ny' },
			{ name: 'Rockville Centre', slug: 'rockville-centre-ny' },
			{ name: 'Rome', slug: 'rome-ny' },
			{ name: 'Rye', slug: 'rye-ny' },
			{ name: 'Saratoga Springs', slug: 'saratoga-springs-ny' },
			{ name: 'Scarsdale', slug: 'scarsdale-ny' },
			{ name: 'Schenectady', slug: 'schenectady-ny' },
			{ name: 'Spring Valley', slug: 'spring-valley-ny' },
			{ name: 'Syracuse', slug: 'syracuse-ny' },
			{ name: 'Tarrytown', slug: 'tarrytown-ny' },
			{ name: 'Tonawanda', slug: 'tonawanda-ny' },
			{ name: 'Troy', slug: 'troy-ny' },
			{ name: 'Utica', slug: 'utica-ny' },
			{ name: 'Valley Stream', slug: 'valley-stream-ny' },
			{ name: 'Warwick', slug: 'warwick-ny' },
			{ name: 'Watertown', slug: 'watertown-ny' },
			{ name: 'Westbury', slug: 'westbury-ny' },
			{ name: 'White Plains', slug: 'white-plains-ny' },
			{ name: 'Yonkers', slug: 'yonkers-ny' },
		],
		stateName: 'New York',
	},
	NC: {
		cities: [
			{ name: 'Albemarle', slug: 'albemarle-nc' },
			{ name: 'Apex', slug: 'apex-nc' },
			{ name: 'Asheboro', slug: 'asheboro-nc' },
			{ name: 'Asheville', slug: 'asheville-nc' },
			{ name: 'Boone', slug: 'boone-nc' },
			{ name: 'Burlington', slug: 'burlington-nc' },
			{ name: 'Cary', slug: 'cary-nc' },
			{ name: 'Chapel Hill', slug: 'chapel-hill-nc' },
			{ name: 'Charlotte', slug: 'charlotte-nc' },
			{ name: 'Clayton', slug: 'clayton-nc' },
			{ name: 'Clemmons', slug: 'clemmons-nc' },
			{ name: 'Concord', slug: 'concord-nc' },
			{ name: 'Cornelius', slug: 'cornelius-nc' },
			{ name: 'Cumberland', slug: 'cumberland-nc' },
			{ name: 'Durham', slug: 'durham-nc' },
			{ name: 'Fayetteville', slug: 'fayetteville-nc' },
			{ name: 'Garner', slug: 'garner-nc' },
			{ name: 'Gastonia', slug: 'gastonia-nc' },
			{ name: 'Goldsboro', slug: 'goldsboro-nc' },
			{ name: 'Greensboro', slug: 'greensboro-nc' },
			{ name: 'Greenville', slug: 'greenville-nc' },
			{ name: 'Hendersonville', slug: 'hendersonville-nc' },
			{ name: 'Hickory', slug: 'hickory-nc' },
			{ name: 'High Point', slug: 'high-point-nc' },
			{ name: 'Huntersville', slug: 'huntersville-nc' },
			{ name: 'Indian Trail', slug: 'indian-trail-nc' },
			{ name: 'Jacksonville', slug: 'jacksonville-nc' },
			{ name: 'Kannapolis', slug: 'kannapolis-nc' },
			{ name: 'Kernersville', slug: 'kernersville-nc' },
			{ name: 'Kinston', slug: 'kinston-nc' },
			{ name: 'Knightdale', slug: 'knightdale-nc' },
			{ name: 'Leland', slug: 'leland-nc' },
			{ name: 'Lenoir', slug: 'lenoir-nc' },
			{ name: 'Lexington', slug: 'lexington-nc' },
			{ name: 'Lincolnton', slug: 'lincolnton-nc' },
			{ name: 'Matthews', slug: 'matthews-nc' },
			{ name: 'Mebane', slug: 'mebane-nc' },
			{ name: 'Monroe', slug: 'monroe-nc' },
			{ name: 'Mooresville', slug: 'mooresville-nc' },
			{ name: 'Morehead City', slug: 'morehead-city-nc' },
			{ name: 'Morganton', slug: 'morganton-nc' },
			{ name: 'Morrisville', slug: 'morrisville-nc' },
			{ name: 'Mount Airy', slug: 'mount-airy-nc' },
			{ name: 'New Bern', slug: 'new-bern-nc' },
			{ name: 'Pinehurst', slug: 'pinehurst-nc' },
			{ name: 'Raleigh', slug: 'raleigh-nc' },
			{ name: 'Rocky Mount', slug: 'rocky-mount-nc' },
			{ name: 'Salisbury', slug: 'salisbury-nc' },
			{ name: 'Sanford', slug: 'sanford-nc' },
			{ name: 'Shelby', slug: 'shelby-nc' },
			{ name: 'Southern Pines', slug: 'southern-pines-nc' },
			{ name: 'Statesville', slug: 'statesville-nc' },
			{ name: 'Swansboro', slug: 'swansboro-nc' },
			{ name: 'Thomasville', slug: 'thomasville-nc' },
			{ name: 'Wake Forest', slug: 'wake-forest-nc' },
			{ name: 'Wilmington', slug: 'wilmington-nc' },
			{ name: 'Wilson', slug: 'wilson-nc' },
			{ name: 'Winston-Salem', slug: 'winston-salem-nc' },
		],
		stateName: 'North Carolina',
	},
	ND: {
		cities: [
			{ name: 'Beach', slug: 'beach-nd' },
			{ name: 'Belfield', slug: 'belfield-nd' },
			{ name: 'Beulah', slug: 'beulah-nd' },
			{ name: 'Bismarck', slug: 'bismarck-nd' },
			{ name: 'Bottineau', slug: 'bottineau-nd' },
			{ name: 'Bowman', slug: 'bowman-nd' },
			{ name: 'Burlington', slug: 'burlington-nd' },
			{ name: 'Cando', slug: 'cando-nd' },
			{ name: 'Carrington', slug: 'carrington-nd' },
			{ name: 'Casselton', slug: 'casselton-nd' },
			{ name: 'Cavalier', slug: 'cavalier-nd' },
			{ name: 'Cooperstown', slug: 'cooperstown-nd' },
			{ name: 'Crosby', slug: 'crosby-nd' },
			{ name: 'Devils Lake', slug: 'devils-lake-nd' },
			{ name: 'Dickinson', slug: 'dickinson-nd' },
			{ name: 'Ellendale', slug: 'ellendale-nd' },
			{ name: 'Fargo', slug: 'fargo-nd' },
			{ name: 'Garrison', slug: 'garrison-nd' },
			{ name: 'Grafton', slug: 'grafton-nd' },
			{ name: 'Grand Forks', slug: 'grand-forks-nd' },
			{ name: 'Harvey', slug: 'harvey-nd' },
			{ name: 'Hazen', slug: 'hazen-nd' },
			{ name: 'Hettinger', slug: 'hettinger-nd' },
			{ name: 'Hillsboro', slug: 'hillsboro-nd' },
			{ name: 'Horace', slug: 'horace-nd' },
			{ name: 'Jamestown', slug: 'jamestown-nd' },
			{ name: 'Kenmare', slug: 'kenmare-nd' },
			{ name: 'Killdeer', slug: 'killdeer-nd' },
			{ name: 'Langdon', slug: 'langdon-nd' },
			{ name: 'Larimore', slug: 'larimore-nd' },
			{ name: 'Linton', slug: 'linton-nd' },
			{ name: 'Lisbon', slug: 'lisbon-nd' },
			{ name: 'Mandan', slug: 'mandan-nd' },
			{ name: 'Mapleton', slug: 'mapleton-nd' },
			{ name: 'Mayville', slug: 'mayville-nd' },
			{ name: 'Minot', slug: 'minot-nd' },
			{ name: 'New Rockford', slug: 'new-rockford-nd' },
			{ name: 'New Salem', slug: 'new-salem-nd' },
			{ name: 'New Town', slug: 'new-town-nd' },
			{ name: 'Northwood', slug: 'northwood-nd' },
			{ name: 'Oakes', slug: 'oakes-nd' },
			{ name: 'Park River', slug: 'park-river-nd' },
			{ name: 'Parshall', slug: 'parshall-nd' },
			{ name: 'Rolla', slug: 'rolla-nd' },
			{ name: 'Rugby', slug: 'rugby-nd' },
			{ name: 'Stanley', slug: 'stanley-nd' },
			{ name: 'Surrey', slug: 'surrey-nd' },
			{ name: 'Thompson', slug: 'thompson-nd' },
			{ name: 'Tioga', slug: 'tioga-nd' },
			{ name: 'Valley City', slug: 'valley-city-nd' },
			{ name: 'Velva', slug: 'velva-nd' },
			{ name: 'Wahpeton', slug: 'wahpeton-nd' },
			{ name: 'Walhalla', slug: 'walhalla-nd' },
			{ name: 'Washburn', slug: 'washburn-nd' },
			{ name: 'Watford City', slug: 'watford-city-nd' },
			{ name: 'West Fargo', slug: 'west-fargo-nd' },
			{ name: 'Williston', slug: 'williston-nd' },
			{ name: 'Wishek', slug: 'wishek-nd' },
		],
		stateName: 'North Dakota',
	},
	OH: {
		cities: [
			{ name: 'Akron', slug: 'akron-oh' },
			{ name: 'Ashland', slug: 'ashland-oh' },
			{ name: 'Athens', slug: 'athens-oh' },
			{ name: 'Avon Lake', slug: 'avon-lake-oh' },
			{ name: 'Avon', slug: 'avon-oh' },
			{ name: 'Beavercreek', slug: 'beavercreek-oh' },
			{ name: 'Belmont', slug: 'belmont-oh' },
			{ name: 'Bowling Green', slug: 'bowling-green-oh' },
			{ name: 'Canton', slug: 'canton-oh' },
			{ name: 'Cincinnati', slug: 'cincinnati-oh' },
			{ name: 'Cleveland', slug: 'cleveland-oh' },
			{ name: 'Columbus', slug: 'columbus-oh' },
			{ name: 'Cuyahoga Falls', slug: 'cuyahoga-falls-oh' },
			{ name: 'Dayton', slug: 'dayton-oh' },
			{ name: 'Deerfield', slug: 'deerfield-oh' },
			{ name: 'Delaware', slug: 'delaware-oh' },
			{ name: 'Dublin', slug: 'dublin-oh' },
			{ name: 'Elyria', slug: 'elyria-oh' },
			{ name: 'Euclid', slug: 'euclid-oh' },
			{ name: 'Fairfield', slug: 'fairfield-oh' },
			{ name: 'Findlay', slug: 'findlay-oh' },
			{ name: 'Grove City', slug: 'grove-city-oh' },
			{ name: 'Hamilton', slug: 'hamilton-oh' },
			{ name: 'Hudson', slug: 'hudson-oh' },
			{ name: 'Kent', slug: 'kent-oh' },
			{ name: 'Lakewood', slug: 'lakewood-oh' },
			{ name: 'Lancaster', slug: 'lancaster-oh' },
			{ name: 'Lima', slug: 'lima-oh' },
			{ name: 'Lorain', slug: 'lorain-oh' },
			{ name: 'Mansfield', slug: 'mansfield-oh' },
			{ name: 'Marion', slug: 'marion-oh' },
			{ name: 'Marysville', slug: 'marysville-oh' },
			{ name: 'Mason', slug: 'mason-oh' },
			{ name: 'Massillon', slug: 'massillon-oh' },
			{ name: 'Medina', slug: 'medina-oh' },
			{ name: 'Mentor', slug: 'mentor-oh' },
			{ name: 'Miamisburg', slug: 'miamisburg-oh' },
			{ name: 'Middletown', slug: 'middletown-oh' },
			{ name: 'Newark', slug: 'newark-oh' },
			{ name: 'North Olmsted', slug: 'north-olmsted-oh' },
			{ name: 'North Ridgeville', slug: 'north-ridgeville-oh' },
			{ name: 'North Royalton', slug: 'north-royalton-oh' },
			{ name: 'Painesville', slug: 'painesville-oh' },
			{ name: 'Perrysburg', slug: 'perrysburg-oh' },
			{ name: 'Pickerington', slug: 'pickerington-oh' },
			{ name: 'Reynoldsburg', slug: 'reynoldsburg-oh' },
			{ name: 'Rocky River', slug: 'rocky-river-oh' },
			{ name: 'Solon', slug: 'solon-oh' },
			{ name: 'Springfield', slug: 'springfield-oh' },
			{ name: 'Stow', slug: 'stow-oh' },
			{ name: 'Strongsville', slug: 'strongsville-oh' },
			{ name: 'Sylvania', slug: 'sylvania-oh' },
			{ name: 'Toledo', slug: 'toledo-oh' },
			{ name: 'Twinsburg', slug: 'twinsburg-oh' },
			{ name: 'West Chester', slug: 'west-chester-oh' },
			{ name: 'Westerville', slug: 'westerville-oh' },
			{ name: 'Westlake', slug: 'westlake-oh' },
			{ name: 'Wooster', slug: 'wooster-oh' },
		],
		stateName: 'Ohio',
	},
	OK: {
		cities: [
			{ name: 'Ada', slug: 'ada-ok' },
			{ name: 'Altus', slug: 'altus-ok' },
			{ name: 'Ardmore', slug: 'ardmore-ok' },
			{ name: 'Bartlesville', slug: 'bartlesville-ok' },
			{ name: 'Bethany', slug: 'bethany-ok' },
			{ name: 'Bixby', slug: 'bixby-ok' },
			{ name: 'Blanchard', slug: 'blanchard-ok' },
			{ name: 'Broken Arrow', slug: 'broken-arrow-ok' },
			{ name: 'Broken Bow', slug: 'broken-bow-ok' },
			{ name: 'Catoosa', slug: 'catoosa-ok' },
			{ name: 'Chickasha', slug: 'chickasha-ok' },
			{ name: 'Choctaw', slug: 'choctaw-ok' },
			{ name: 'Claremore', slug: 'claremore-ok' },
			{ name: 'Clinton', slug: 'clinton-ok' },
			{ name: 'Collinsville', slug: 'collinsville-ok' },
			{ name: 'Coweta', slug: 'coweta-ok' },
			{ name: 'Cushing', slug: 'cushing-ok' },
			{ name: 'Duncan', slug: 'duncan-ok' },
			{ name: 'Durant', slug: 'durant-ok' },
			{ name: 'Edmond', slug: 'edmond-ok' },
			{ name: 'El Reno', slug: 'el-reno-ok' },
			{ name: 'Elk City', slug: 'elk-city-ok' },
			{ name: 'Enid', slug: 'enid-ok' },
			{ name: 'Glenpool', slug: 'glenpool-ok' },
			{ name: 'Grove', slug: 'grove-ok' },
			{ name: 'Guthrie', slug: 'guthrie-ok' },
			{ name: 'Guymon', slug: 'guymon-ok' },
			{ name: 'Jenks', slug: 'jenks-ok' },
			{ name: 'Kingfisher', slug: 'kingfisher-ok' },
			{ name: 'Lawton', slug: 'lawton-ok' },
			{ name: 'McAlester', slug: 'mcalester-ok' },
			{ name: 'Miami', slug: 'miami-ok' },
			{ name: 'Midwest City', slug: 'midwest-city-ok' },
			{ name: 'Moore', slug: 'moore-ok' },
			{ name: 'Muskogee', slug: 'muskogee-ok' },
			{ name: 'Mustang', slug: 'mustang-ok' },
			{ name: 'Newcastle', slug: 'newcastle-ok' },
			{ name: 'Norman', slug: 'norman-ok' },
			{ name: 'Oklahoma City', slug: 'oklahoma-city-ok' },
			{ name: 'Okmulgee', slug: 'okmulgee-ok' },
			{ name: 'Owasso', slug: 'owasso-ok' },
			{ name: 'Piedmont', slug: 'piedmont-ok' },
			{ name: 'Ponca City', slug: 'ponca-city-ok' },
			{ name: 'Poteau', slug: 'poteau-ok' },
			{ name: 'Purcell', slug: 'purcell-ok' },
			{ name: 'Sallisaw', slug: 'sallisaw-ok' },
			{ name: 'Sand Springs', slug: 'sand-springs-ok' },
			{ name: 'Sapulpa', slug: 'sapulpa-ok' },
			{ name: 'Seminole', slug: 'seminole-ok' },
			{ name: 'Shawnee', slug: 'shawnee-ok' },
			{ name: 'Skiatook', slug: 'skiatook-ok' },
			{ name: 'Stillwater', slug: 'stillwater-ok' },
			{ name: 'Tahlequah', slug: 'tahlequah-ok' },
			{ name: 'Tulsa', slug: 'tulsa-ok' },
			{ name: 'Wagoner', slug: 'wagoner-ok' },
			{ name: 'Weatherford', slug: 'weatherford-ok' },
			{ name: 'Woodward', slug: 'woodward-ok' },
			{ name: 'Yukon', slug: 'yukon-ok' },
		],
		stateName: 'Oklahoma',
	},
	OR: {
		cities: [
			{ name: 'Albany', slug: 'albany-or' },
			{ name: 'Ashland', slug: 'ashland-or' },
			{ name: 'Astoria', slug: 'astoria-or' },
			{ name: 'Beaverton', slug: 'beaverton-or' },
			{ name: 'Bend', slug: 'bend-or' },
			{ name: 'Boardman', slug: 'boardman-or' },
			{ name: 'Brookings', slug: 'brookings-or' },
			{ name: 'Canby', slug: 'canby-or' },
			{ name: 'Central Point', slug: 'central-point-or' },
			{ name: 'Coos Bay', slug: 'coos-bay-or' },
			{ name: 'Cornelius', slug: 'cornelius-or' },
			{ name: 'Corvallis', slug: 'corvallis-or' },
			{ name: 'Cottage Grove', slug: 'cottage-grove-or' },
			{ name: 'Dallas', slug: 'dallas-or' },
			{ name: 'Damascus', slug: 'damascus-or' },
			{ name: 'Eugene', slug: 'eugene-or' },
			{ name: 'Forest Grove', slug: 'forest-grove-or' },
			{ name: 'Gladstone', slug: 'gladstone-or' },
			{ name: 'Grants Pass', slug: 'grants-pass-or' },
			{ name: 'Gresham', slug: 'gresham-or' },
			{ name: 'Happy Valley', slug: 'happy-valley-or' },
			{ name: 'Hermiston', slug: 'hermiston-or' },
			{ name: 'Hillsboro', slug: 'hillsboro-or' },
			{ name: 'Hood River', slug: 'hood-river-or' },
			{ name: 'Keizer', slug: 'keizer-or' },
			{ name: 'Klamath Falls', slug: 'klamath-falls-or' },
			{ name: 'La Grande', slug: 'la-grande-or' },
			{ name: 'Lake Oswego', slug: 'lake-oswego-or' },
			{ name: 'Lebanon', slug: 'lebanon-or' },
			{ name: 'Lincoln City', slug: 'lincoln-city-or' },
			{ name: 'McMinnville', slug: 'mcminnville-or' },
			{ name: 'Medford', slug: 'medford-or' },
			{ name: 'Milwaukie', slug: 'milwaukie-or' },
			{ name: 'Monmouth', slug: 'monmouth-or' },
			{ name: 'Newberg', slug: 'newberg-or' },
			{ name: 'Newport', slug: 'newport-or' },
			{ name: 'Ontario', slug: 'ontario-or' },
			{ name: 'Oregon City', slug: 'oregon-city-or' },
			{ name: 'Pendleton', slug: 'pendleton-or' },
			{ name: 'Portland', slug: 'portland-or' },
			{ name: 'Prineville', slug: 'prineville-or' },
			{ name: 'Redmond', slug: 'redmond-or' },
			{ name: 'Roseburg', slug: 'roseburg-or' },
			{ name: 'Saint Helens', slug: 'saint-helens-or' },
			{ name: 'Salem', slug: 'salem-or' },
			{ name: 'Sandy', slug: 'sandy-or' },
			{ name: 'Sherwood', slug: 'sherwood-or' },
			{ name: 'Silverton', slug: 'silverton-or' },
			{ name: 'Springfield', slug: 'springfield-or' },
			{ name: 'Stayton', slug: 'stayton-or' },
			{ name: 'The Dalles', slug: 'the-dalles-or' },
			{ name: 'Tigard', slug: 'tigard-or' },
			{ name: 'Tillamook', slug: 'tillamook-or' },
			{ name: 'Troutdale', slug: 'troutdale-or' },
			{ name: 'Tualatin', slug: 'tualatin-or' },
			{ name: 'West Linn', slug: 'west-linn-or' },
			{ name: 'Wilsonville', slug: 'wilsonville-or' },
			{ name: 'Woodburn', slug: 'woodburn-or' },
		],
		stateName: 'Oregon',
	},
	PA: {
		cities: [
			{ name: 'Abington', slug: 'abington-pa' },
			{ name: 'Allentown', slug: 'allentown-pa' },
			{ name: 'Altoona', slug: 'altoona-pa' },
			{ name: 'Bedford', slug: 'bedford-pa' },
			{ name: 'Bensalem', slug: 'bensalem-pa' },
			{ name: 'Bethel Park', slug: 'bethel-park-pa' },
			{ name: 'Bethlehem', slug: 'bethlehem-pa' },
			{ name: 'Bloomsburg', slug: 'bloomsburg-pa' },
			{ name: 'Bristol', slug: 'bristol-pa' },
			{ name: 'Butler', slug: 'butler-pa' },
			{ name: 'Carlisle', slug: 'carlisle-pa' },
			{ name: 'Chambersburg', slug: 'chambersburg-pa' },
			{ name: 'Chester', slug: 'chester-pa' },
			{ name: 'Coatesville', slug: 'coatesville-pa' },
			{ name: 'Easton', slug: 'easton-pa' },
			{ name: 'Elizabethtown', slug: 'elizabethtown-pa' },
			{ name: 'Ellwood City', slug: 'ellwood-city-pa' },
			{ name: 'Emmaus', slug: 'emmaus-pa' },
			{ name: 'Ephrata', slug: 'ephrata-pa' },
			{ name: 'Erie', slug: 'erie-pa' },
			{ name: 'Greensburg', slug: 'greensburg-pa' },
			{ name: 'Grove City', slug: 'grove-city-pa' },
			{ name: 'Harrisburg', slug: 'harrisburg-pa' },
			{ name: 'Hazleton', slug: 'hazleton-pa' },
			{ name: 'Hermitage', slug: 'hermitage-pa' },
			{ name: 'Indiana', slug: 'indiana-pa' },
			{ name: 'Johnstown', slug: 'johnstown-pa' },
			{ name: 'Kittanning', slug: 'kittanning-pa' },
			{ name: 'Kutztown', slug: 'kutztown-pa' },
			{ name: 'Lancaster', slug: 'lancaster-pa' },
			{ name: 'Lansdale', slug: 'lansdale-pa' },
			{ name: 'Lebanon', slug: 'lebanon-pa' },
			{ name: 'McKeesport', slug: 'mckeesport-pa' },
			{ name: 'Meadville', slug: 'meadville-pa' },
			{ name: 'Monessen', slug: 'monessen-pa' },
			{ name: 'Monroeville', slug: 'monroeville-pa' },
			{ name: 'Murrysville', slug: 'murrysville-pa' },
			{ name: 'New Castle', slug: 'new-castle-pa' },
			{ name: 'New Kensington', slug: 'new-kensington-pa' },
			{ name: 'Norristown', slug: 'norristown-pa' },
			{ name: 'Northampton', slug: 'northampton-pa' },
			{ name: 'Philadelphia', slug: 'philadelphia-pa' },
			{ name: 'Phoenixville', slug: 'phoenixville-pa' },
			{ name: 'Pittsburgh', slug: 'pittsburgh-pa' },
			{ name: 'Pottstown', slug: 'pottstown-pa' },
			{ name: 'Reading', slug: 'reading-pa' },
			{ name: 'Scranton', slug: 'scranton-pa' },
			{ name: 'Shippensburg', slug: 'shippensburg-pa' },
			{ name: 'Somerset', slug: 'somerset-pa' },
			{ name: 'State College', slug: 'state-college-pa' },
			{ name: 'Uniontown', slug: 'uniontown-pa' },
			{ name: 'Upper Darby', slug: 'upper-darby-pa' },
			{ name: 'Washington', slug: 'washington-pa' },
			{ name: 'West Chester', slug: 'west-chester-pa' },
			{ name: 'West Mifflin', slug: 'west-mifflin-pa' },
			{ name: 'Wilkes-Barre', slug: 'wilkes-barre-pa' },
			{ name: 'Williamsport', slug: 'williamsport-pa' },
			{ name: 'York', slug: 'york-pa' },
		],
		stateName: 'Pennsylvania',
	},
	RI: {
		cities: [
			{ name: 'Central Falls', slug: 'central-falls-ri' },
			{ name: 'Cranston', slug: 'cranston-ri' },
			{ name: 'East Providence', slug: 'east-providence-ri' },
			{ name: 'Newport', slug: 'newport-ri' },
			{ name: 'Pawtucket', slug: 'pawtucket-ri' },
			{ name: 'Providence', slug: 'providence-ri' },
			{ name: 'Warwick', slug: 'warwick-ri' },
			{ name: 'Woonsocket', slug: 'woonsocket-ri' },
		],
		stateName: 'Rhode Island',
	},
	SC: {
		cities: [
			{ name: 'Abbeville', slug: 'abbeville-sc' },
			{ name: 'Aiken', slug: 'aiken-sc' },
			{ name: 'Anderson', slug: 'anderson-sc' },
			{ name: 'Beaufort', slug: 'beaufort-sc' },
			{ name: 'Bennettsville', slug: 'bennettsville-sc' },
			{ name: 'Bluffton', slug: 'bluffton-sc' },
			{ name: 'Blythewood', slug: 'blythewood-sc' },
			{ name: 'Camden', slug: 'camden-sc' },
			{ name: 'Cayce', slug: 'cayce-sc' },
			{ name: 'Charleston', slug: 'charleston-sc' },
			{ name: 'Clemson', slug: 'clemson-sc' },
			{ name: 'Clover', slug: 'clover-sc' },
			{ name: 'Columbia', slug: 'columbia-sc' },
			{ name: 'Conway', slug: 'conway-sc' },
			{ name: 'Darlington', slug: 'darlington-sc' },
			{ name: 'Dorchester', slug: 'dorchester-sc' },
			{ name: 'Easley', slug: 'easley-sc' },
			{ name: 'Florence', slug: 'florence-sc' },
			{ name: 'Fort Mill', slug: 'fort-mill-sc' },
			{ name: 'Gaffney', slug: 'gaffney-sc' },
			{ name: 'Georgetown', slug: 'georgetown-sc' },
			{ name: 'Goose Creek', slug: 'goose-creek-sc' },
			{ name: 'Greenville', slug: 'greenville-sc' },
			{ name: 'Greenwood', slug: 'greenwood-sc' },
			{ name: 'Greer', slug: 'greer-sc' },
			{ name: 'Hanahan', slug: 'hanahan-sc' },
			{ name: 'Hardeeville', slug: 'hardeeville-sc' },
			{ name: 'Hartsville', slug: 'hartsville-sc' },
			{ name: 'Hilton Head Island', slug: 'hilton-head-island-sc' },
			{ name: 'Irmo', slug: 'irmo-sc' },
			{ name: 'Lancaster', slug: 'lancaster-sc' },
			{ name: 'Laurens', slug: 'laurens-sc' },
			{ name: 'Lexington', slug: 'lexington-sc' },
			{ name: 'Lyman', slug: 'lyman-sc' },
			{ name: 'Manning', slug: 'manning-sc' },
			{ name: 'Mauldin', slug: 'mauldin-sc' },
			{ name: 'Moncks Corner', slug: 'moncks-corner-sc' },
			{ name: 'Mount Pleasant', slug: 'mount-pleasant-sc' },
			{ name: 'Myrtle Beach', slug: 'myrtle-beach-sc' },
			{ name: 'Newberry', slug: 'newberry-sc' },
			{ name: 'North Augusta', slug: 'north-augusta-sc' },
			{ name: 'North Charleston', slug: 'north-charleston-sc' },
			{ name: 'North Myrtle Beach', slug: 'north-myrtle-beach-sc' },
			{ name: 'Orangeburg', slug: 'orangeburg-sc' },
			{ name: 'Port Royal', slug: 'port-royal-sc' },
			{ name: 'Richland', slug: 'richland-sc' },
			{ name: 'Rock Hill', slug: 'rock-hill-sc' },
			{ name: 'Seneca', slug: 'seneca-sc' },
			{ name: 'Simpsonville', slug: 'simpsonville-sc' },
			{ name: 'Spartanburg', slug: 'spartanburg-sc' },
			{ name: 'Summerville', slug: 'summerville-sc' },
			{ name: 'Sumter', slug: 'sumter-sc' },
			{ name: 'Surfside Beach', slug: 'surfside-beach-sc' },
			{ name: 'Travelers Rest', slug: 'travelers-rest-sc' },
			{ name: 'Walterboro', slug: 'walterboro-sc' },
			{ name: 'West Columbia', slug: 'west-columbia-sc' },
			{ name: 'Williamston', slug: 'williamston-sc' },
			{ name: 'York', slug: 'york-sc' },
		],
		stateName: 'South Carolina',
	},
	SD: {
		cities: [
			{ name: 'Aberdeen', slug: 'aberdeen-sd' },
			{ name: 'Baltic', slug: 'baltic-sd' },
			{ name: 'Belle Fourche', slug: 'belle-fourche-sd' },
			{ name: 'Beresford', slug: 'beresford-sd' },
			{ name: 'Box Elder', slug: 'box-elder-sd' },
			{ name: 'Brandon', slug: 'brandon-sd' },
			{ name: 'Britton', slug: 'britton-sd' },
			{ name: 'Brookings', slug: 'brookings-sd' },
			{ name: 'Canton', slug: 'canton-sd' },
			{ name: 'Chamberlain', slug: 'chamberlain-sd' },
			{ name: 'Clear Lake', slug: 'clear-lake-sd' },
			{ name: 'Crooks', slug: 'crooks-sd' },
			{ name: 'Custer', slug: 'custer-sd' },
			{ name: 'Deadwood', slug: 'deadwood-sd' },
			{ name: 'Dell Rapids', slug: 'dell-rapids-sd' },
			{ name: 'Eagle Butte', slug: 'eagle-butte-sd' },
			{ name: 'Elk Point', slug: 'elk-point-sd' },
			{ name: 'Flandreau', slug: 'flandreau-sd' },
			{ name: 'Fort Pierre', slug: 'fort-pierre-sd' },
			{ name: 'Freeman', slug: 'freeman-sd' },
			{ name: 'Garretson', slug: 'garretson-sd' },
			{ name: 'Gettysburg', slug: 'gettysburg-sd' },
			{ name: 'Gregory', slug: 'gregory-sd' },
			{ name: 'Groton', slug: 'groton-sd' },
			{ name: 'Harrisburg', slug: 'harrisburg-sd' },
			{ name: 'Hartford', slug: 'hartford-sd' },
			{ name: 'Hot Springs', slug: 'hot-springs-sd' },
			{ name: 'Huron', slug: 'huron-sd' },
			{ name: 'Lead', slug: 'lead-sd' },
			{ name: 'Lemmon', slug: 'lemmon-sd' },
			{ name: 'Lennox', slug: 'lennox-sd' },
			{ name: 'Madison', slug: 'madison-sd' },
			{ name: 'Milbank', slug: 'milbank-sd' },
			{ name: 'Miller', slug: 'miller-sd' },
			{ name: 'Mission', slug: 'mission-sd' },
			{ name: 'Mitchell', slug: 'mitchell-sd' },
			{ name: 'Mobridge', slug: 'mobridge-sd' },
			{ name: 'North Sioux City', slug: 'north-sioux-city-sd' },
			{ name: 'Parkston', slug: 'parkston-sd' },
			{ name: 'Pierre', slug: 'pierre-sd' },
			{ name: 'Platte', slug: 'platte-sd' },
			{ name: 'Rapid City', slug: 'rapid-city-sd' },
			{ name: 'Redfield', slug: 'redfield-sd' },
			{ name: 'Salem', slug: 'salem-sd' },
			{ name: 'Sioux Falls', slug: 'sioux-falls-sd' },
			{ name: 'Sisseton', slug: 'sisseton-sd' },
			{ name: 'Spearfish', slug: 'spearfish-sd' },
			{ name: 'Springfield', slug: 'springfield-sd' },
			{ name: 'Sturgis', slug: 'sturgis-sd' },
			{ name: 'Summerset', slug: 'summerset-sd' },
			{ name: 'Tea', slug: 'tea-sd' },
			{ name: 'Vermillion', slug: 'vermillion-sd' },
			{ name: 'Volga', slug: 'volga-sd' },
			{ name: 'Wagner', slug: 'wagner-sd' },
			{ name: 'Watertown', slug: 'watertown-sd' },
			{ name: 'Webster', slug: 'webster-sd' },
			{ name: 'Winner', slug: 'winner-sd' },
			{ name: 'Yankton', slug: 'yankton-sd' },
		],
		stateName: 'South Dakota',
	},
	TN: {
		cities: [
			{ name: 'Arlington', slug: 'arlington-tn' },
			{ name: 'Athens', slug: 'athens-tn' },
			{ name: 'Bartlett', slug: 'bartlett-tn' },
			{ name: 'Brentwood', slug: 'brentwood-tn' },
			{ name: 'Bristol', slug: 'bristol-tn' },
			{ name: 'Chattanooga', slug: 'chattanooga-tn' },
			{ name: 'Clarksville', slug: 'clarksville-tn' },
			{ name: 'Cleveland', slug: 'cleveland-tn' },
			{ name: 'Collegedale', slug: 'collegedale-tn' },
			{ name: 'Collierville', slug: 'collierville-tn' },
			{ name: 'Columbia', slug: 'columbia-tn' },
			{ name: 'Cookeville', slug: 'cookeville-tn' },
			{ name: 'Crossville', slug: 'crossville-tn' },
			{ name: 'Dayton', slug: 'dayton-tn' },
			{ name: 'Dickson', slug: 'dickson-tn' },
			{ name: 'Dyersburg', slug: 'dyersburg-tn' },
			{ name: 'Elizabethton', slug: 'elizabethton-tn' },
			{ name: 'Franklin', slug: 'franklin-tn' },
			{ name: 'Gallatin', slug: 'gallatin-tn' },
			{ name: 'Germantown', slug: 'germantown-tn' },
			{ name: 'Greeneville', slug: 'greeneville-tn' },
			{ name: 'Henderson', slug: 'henderson-tn' },
			{ name: 'Hendersonville', slug: 'hendersonville-tn' },
			{ name: 'Hickman', slug: 'hickman-tn' },
			{ name: 'Jackson', slug: 'jackson-tn' },
			{ name: 'Johnson City', slug: 'johnson-city-tn' },
			{ name: 'Jonesborough', slug: 'jonesborough-tn' },
			{ name: 'Kingsport', slug: 'kingsport-tn' },
			{ name: 'Knoxville', slug: 'knoxville-tn' },
			{ name: 'La Vergne', slug: 'la-vergne-tn' },
			{ name: 'Lawrenceburg', slug: 'lawrenceburg-tn' },
			{ name: 'Lebanon', slug: 'lebanon-tn' },
			{ name: 'Lenoir City', slug: 'lenoir-city-tn' },
			{ name: 'Lewisburg', slug: 'lewisburg-tn' },
			{ name: 'Macon', slug: 'macon-tn' },
			{ name: 'Manchester', slug: 'manchester-tn' },
			{ name: 'Martin', slug: 'martin-tn' },
			{ name: 'Maryville', slug: 'maryville-tn' },
			{ name: 'McMinnville', slug: 'mcminnville-tn' },
			{ name: 'Memphis', slug: 'memphis-tn' },
			{ name: 'Millington', slug: 'millington-tn' },
			{ name: 'Monroe', slug: 'monroe-tn' },
			{ name: 'Morristown', slug: 'morristown-tn' },
			{ name: 'Mount Juliet', slug: 'mount-juliet-tn' },
			{ name: 'Murfreesboro', slug: 'murfreesboro-tn' },
			{ name: 'Nashville', slug: 'nashville-tn' },
			{ name: 'Oak Ridge', slug: 'oak-ridge-tn' },
			{ name: 'Oakland', slug: 'oakland-tn' },
			{ name: 'Paris', slug: 'paris-tn' },
			{ name: 'Pigeon Forge', slug: 'pigeon-forge-tn' },
			{ name: 'Portland', slug: 'portland-tn' },
			{ name: 'Sevierville', slug: 'sevierville-tn' },
			{ name: 'Shelbyville', slug: 'shelbyville-tn' },
			{ name: 'Smyrna', slug: 'smyrna-tn' },
			{ name: 'Spring Hill', slug: 'spring-hill-tn' },
			{ name: 'Springfield', slug: 'springfield-tn' },
			{ name: 'Tullahoma', slug: 'tullahoma-tn' },
			{ name: 'Winchester', slug: 'winchester-tn' },
		],
		stateName: 'Tennessee',
	},
	TX: {
		cities: [
			{ name: 'Abilene', slug: 'abilene-tx' },
			{ name: 'Allen', slug: 'allen-tx' },
			{ name: 'Amarillo', slug: 'amarillo-tx' },
			{ name: 'Arlington', slug: 'arlington-tx' },
			{ name: 'Austin', slug: 'austin-tx' },
			{ name: 'Baytown', slug: 'baytown-tx' },
			{ name: 'Beaumont', slug: 'beaumont-tx' },
			{ name: 'Brownsville', slug: 'brownsville-tx' },
			{ name: 'Carrollton', slug: 'carrollton-tx' },
			{ name: 'College Station', slug: 'college-station-tx' },
			{ name: 'Conroe', slug: 'conroe-tx' },
			{ name: 'Coppell', slug: 'coppell-tx' },
			{ name: 'Corpus Christi', slug: 'corpus-christi-tx' },
			{ name: 'Dallas', slug: 'dallas-tx' },
			{ name: 'Denton', slug: 'denton-tx' },
			{ name: 'El Paso', slug: 'el-paso-tx' },
			{ name: 'Flower Mound', slug: 'flower-mound-tx' },
			{ name: 'Fort Worth', slug: 'fort-worth-tx' },
			{ name: 'Frisco', slug: 'frisco-tx' },
			{ name: 'Galveston', slug: 'galveston-tx' },
			{ name: 'Garland', slug: 'garland-tx' },
			{ name: 'Georgetown', slug: 'georgetown-tx' },
			{ name: 'Grand Prairie', slug: 'grand-prairie-tx' },
			{ name: 'Grapevine', slug: 'grapevine-tx' },
			{ name: 'Houston', slug: 'houston-tx' },
			{ name: 'Irving', slug: 'irving-tx' },
			{ name: 'Killeen', slug: 'killeen-tx' },
			{ name: 'Kyle', slug: 'kyle-tx' },
			{ name: 'Laredo', slug: 'laredo-tx' },
			{ name: 'League City', slug: 'league-city-tx' },
			{ name: 'Lewisville', slug: 'lewisville-tx' },
			{ name: 'Longview', slug: 'longview-tx' },
			{ name: 'Lubbock', slug: 'lubbock-tx' },
			{ name: 'Mansfield', slug: 'mansfield-tx' },
			{ name: 'McAllen', slug: 'mcallen-tx' },
			{ name: 'McKinney', slug: 'mckinney-tx' },
			{ name: 'Mesquite', slug: 'mesquite-tx' },
			{ name: 'Midland', slug: 'midland-tx' },
			{ name: 'Missouri City', slug: 'missouri-city-tx' },
			{ name: 'New Braunfels', slug: 'new-braunfels-tx' },
			{ name: 'North Richland Hills', slug: 'north-richland-hills-tx' },
			{ name: 'Odessa', slug: 'odessa-tx' },
			{ name: 'Pasadena', slug: 'pasadena-tx' },
			{ name: 'Pearland', slug: 'pearland-tx' },
			{ name: 'Pflugerville', slug: 'pflugerville-tx' },
			{ name: 'Plano', slug: 'plano-tx' },
			{ name: 'Richardson', slug: 'richardson-tx' },
			{ name: 'Rockwall', slug: 'rockwall-tx' },
			{ name: 'Rosenberg', slug: 'rosenberg-tx' },
			{ name: 'Round Rock', slug: 'round-rock-tx' },
			{ name: 'San Angelo', slug: 'san-angelo-tx' },
			{ name: 'San Antonio', slug: 'san-antonio-tx' },
			{ name: 'San Marcos', slug: 'san-marcos-tx' },
			{ name: 'Sugar Land', slug: 'sugar-land-tx' },
			{ name: 'Temple', slug: 'temple-tx' },
			{ name: 'Tyler', slug: 'tyler-tx' },
			{ name: 'Waco', slug: 'waco-tx' },
			{ name: 'Wichita Falls', slug: 'wichita-falls-tx' },
		],
		stateName: 'Texas',
	},
	UT: {
		cities: [
			{ name: 'Alpine', slug: 'alpine-ut' },
			{ name: 'American Fork', slug: 'american-fork-ut' },
			{ name: 'Bluff', slug: 'bluff-ut' },
			{ name: 'Bountiful', slug: 'bountiful-ut' },
			{ name: 'Brigham City', slug: 'brigham-city-ut' },
			{ name: 'Cedar City', slug: 'cedar-city-ut' },
			{ name: 'Centerville', slug: 'centerville-ut' },
			{ name: 'Clearfield', slug: 'clearfield-ut' },
			{ name: 'Draper', slug: 'draper-ut' },
			{ name: 'Eagle Mountain', slug: 'eagle-mountain-ut' },
			{ name: 'Emery', slug: 'emery-ut' },
			{ name: 'Farmington', slug: 'farmington-ut' },
			{ name: 'Grantsville', slug: 'grantsville-ut' },
			{ name: 'Heber City', slug: 'heber-city-ut' },
			{ name: 'Herriman', slug: 'herriman-ut' },
			{ name: 'Holladay', slug: 'holladay-ut' },
			{ name: 'Hooper', slug: 'hooper-ut' },
			{ name: 'Hurricane', slug: 'hurricane-ut' },
			{ name: 'Hyrum', slug: 'hyrum-ut' },
			{ name: 'Ivins', slug: 'ivins-ut' },
			{ name: 'Kaysville', slug: 'kaysville-ut' },
			{ name: 'Layton', slug: 'layton-ut' },
			{ name: 'Lehi', slug: 'lehi-ut' },
			{ name: 'Lindon', slug: 'lindon-ut' },
			{ name: 'Logan', slug: 'logan-ut' },
			{ name: 'Mapleton', slug: 'mapleton-ut' },
			{ name: 'Midvale', slug: 'midvale-ut' },
			{ name: 'Morgan', slug: 'morgan-ut' },
			{ name: 'Murray', slug: 'murray-ut' },
			{ name: 'North Salt Lake', slug: 'north-salt-lake-ut' },
			{ name: 'Ogden', slug: 'ogden-ut' },
			{ name: 'Orem', slug: 'orem-ut' },
			{ name: 'Park City', slug: 'park-city-ut' },
			{ name: 'Payson', slug: 'payson-ut' },
			{ name: 'Pleasant Grove', slug: 'pleasant-grove-ut' },
			{ name: 'Price', slug: 'price-ut' },
			{ name: 'Provo', slug: 'provo-ut' },
			{ name: 'Richfield', slug: 'richfield-ut' },
			{ name: 'Riverton', slug: 'riverton-ut' },
			{ name: 'Roy', slug: 'roy-ut' },
			{ name: 'Saint George', slug: 'saint-george-ut' },
			{ name: 'Salt Lake City', slug: 'salt-lake-city-ut' },
			{ name: 'Santaquin', slug: 'santaquin-ut' },
			{ name: 'Saratoga Springs', slug: 'saratoga-springs-ut' },
			{ name: 'Sevier', slug: 'sevier-ut' },
			{ name: 'Smithfield', slug: 'smithfield-ut' },
			{ name: 'South Jordan', slug: 'south-jordan-ut' },
			{ name: 'Spanish Fork', slug: 'spanish-fork-ut' },
			{ name: 'Springville', slug: 'springville-ut' },
			{ name: 'Summit', slug: 'summit-ut' },
			{ name: 'Syracuse', slug: 'syracuse-ut' },
			{ name: 'Tooele', slug: 'tooele-ut' },
			{ name: 'Tremonton', slug: 'tremonton-ut' },
			{ name: 'Vernal', slug: 'vernal-ut' },
			{ name: 'Washington', slug: 'washington-ut' },
			{ name: 'West Jordan', slug: 'west-jordan-ut' },
			{ name: 'West Valley City', slug: 'west-valley-city-ut' },
			{ name: 'Woods Cross', slug: 'woods-cross-ut' },
		],
		stateName: 'Utah',
	},
	VT: {
		cities: [
			{ name: 'Albany', slug: 'albany-vt' },
			{ name: 'Barre', slug: 'barre-vt' },
			{ name: 'Barton', slug: 'barton-vt' },
			{ name: 'Bellows Falls', slug: 'bellows-falls-vt' },
			{ name: 'Burlington', slug: 'burlington-vt' },
			{ name: 'Cambridge', slug: 'cambridge-vt' },
			{ name: 'Derby Line', slug: 'derby-line-vt' },
			{ name: 'Enosburg Falls', slug: 'enosburg-falls-vt' },
			{ name: 'Essex Junction', slug: 'essex-junction-vt' },
			{ name: 'Hyde Park', slug: 'hyde-park-vt' },
			{ name: 'Jacksonville', slug: 'jacksonville-vt' },
			{ name: 'Jeffersonville', slug: 'jeffersonville-vt' },
			{ name: 'Johnson', slug: 'johnson-vt' },
			{ name: 'Ludlow', slug: 'ludlow-vt' },
			{ name: 'Lyndonville', slug: 'lyndonville-vt' },
			{ name: 'Manchester', slug: 'manchester-vt' },
			{ name: 'Marshfield', slug: 'marshfield-vt' },
			{ name: 'Montpelier', slug: 'montpelier-vt' },
			{ name: 'Morrisville', slug: 'morrisville-vt' },
			{ name: 'Newbury', slug: 'newbury-vt' },
			{ name: 'Newfane', slug: 'newfane-vt' },
			{ name: 'Newport', slug: 'newport-vt' },
			{ name: 'North Bennington', slug: 'north-bennington-vt' },
			{ name: 'North Troy', slug: 'north-troy-vt' },
			{ name: 'Orleans', slug: 'orleans-vt' },
			{ name: 'Perkinsville', slug: 'perkinsville-vt' },
			{ name: 'Poultney', slug: 'poultney-vt' },
			{ name: 'Rutland', slug: 'rutland-vt' },
			{ name: 'Saint Albans', slug: 'saint-albans-vt' },
			{ name: 'Saxtons River', slug: 'saxtons-river-vt' },
			{ name: 'South Burlington', slug: 'south-burlington-vt' },
			{ name: 'Swanton', slug: 'swanton-vt' },
			{ name: 'Vergennes', slug: 'vergennes-vt' },
			{ name: 'Washington', slug: 'washington-vt' },
			{ name: 'Waterbury', slug: 'waterbury-vt' },
			{ name: 'Wells River', slug: 'wells-river-vt' },
			{ name: 'West Burke', slug: 'west-burke-vt' },
			{ name: 'Westminster', slug: 'westminster-vt' },
			{ name: 'Windsor', slug: 'windsor-vt' },
			{ name: 'Winooski', slug: 'winooski-vt' },
			{ name: 'Woodstock', slug: 'woodstock-vt' },
		],
		stateName: 'Vermont',
	},
	VA: {
		cities: [
			{ name: 'Abingdon', slug: 'abingdon-va' },
			{ name: 'Alexandria', slug: 'alexandria-va' },
			{ name: 'Ashland', slug: 'ashland-va' },
			{ name: 'Bedford', slug: 'bedford-va' },
			{ name: 'Blacksburg', slug: 'blacksburg-va' },
			{ name: 'Bristol', slug: 'bristol-va' },
			{ name: 'Charlottesville', slug: 'charlottesville-va' },
			{ name: 'Chesapeake', slug: 'chesapeake-va' },
			{ name: 'Chesterfield', slug: 'chesterfield-va' },
			{ name: 'Christiansburg', slug: 'christiansburg-va' },
			{ name: 'Colonial Heights', slug: 'colonial-heights-va' },
			{ name: 'Covington', slug: 'covington-va' },
			{ name: 'Culpeper', slug: 'culpeper-va' },
			{ name: 'Danville', slug: 'danville-va' },
			{ name: 'Dumfries', slug: 'dumfries-va' },
			{ name: 'Fairfax', slug: 'fairfax-va' },
			{ name: 'Falls Church', slug: 'falls-church-va' },
			{ name: 'Farmville', slug: 'farmville-va' },
			{ name: 'Fredericksburg', slug: 'fredericksburg-va' },
			{ name: 'Front Royal', slug: 'front-royal-va' },
			{ name: 'Galax', slug: 'galax-va' },
			{ name: 'Hampton', slug: 'hampton-va' },
			{ name: 'Harrisonburg', slug: 'harrisonburg-va' },
			{ name: 'Herndon', slug: 'herndon-va' },
			{ name: 'Hopewell', slug: 'hopewell-va' },
			{ name: 'Leesburg', slug: 'leesburg-va' },
			{ name: 'Lexington', slug: 'lexington-va' },
			{ name: 'Luray', slug: 'luray-va' },
			{ name: 'Lynchburg', slug: 'lynchburg-va' },
			{ name: 'Manassas', slug: 'manassas-va' },
			{ name: 'Martinsville', slug: 'martinsville-va' },
			{ name: 'Newport News', slug: 'newport-news-va' },
			{ name: 'Norfolk', slug: 'norfolk-va' },
			{ name: 'Orange', slug: 'orange-va' },
			{ name: 'Petersburg', slug: 'petersburg-va' },
			{ name: 'Poquoson', slug: 'poquoson-va' },
			{ name: 'Portsmouth', slug: 'portsmouth-va' },
			{ name: 'Pulaski', slug: 'pulaski-va' },
			{ name: 'Purcellville', slug: 'purcellville-va' },
			{ name: 'Radford', slug: 'radford-va' },
			{ name: 'Richmond', slug: 'richmond-va' },
			{ name: 'Roanoke', slug: 'roanoke-va' },
			{ name: 'Rocky Mount', slug: 'rocky-mount-va' },
			{ name: 'Salem', slug: 'salem-va' },
			{ name: 'Smithfield', slug: 'smithfield-va' },
			{ name: 'South Boston', slug: 'south-boston-va' },
			{ name: 'Staunton', slug: 'staunton-va' },
			{ name: 'Strasburg', slug: 'strasburg-va' },
			{ name: 'Suffolk', slug: 'suffolk-va' },
			{ name: 'Vienna', slug: 'vienna-va' },
			{ name: 'Virginia Beach', slug: 'virginia-beach-va' },
			{ name: 'Warrenton', slug: 'warrenton-va' },
			{ name: 'Waynesboro', slug: 'waynesboro-va' },
			{ name: 'Williamsburg', slug: 'williamsburg-va' },
			{ name: 'Winchester', slug: 'winchester-va' },
			{ name: 'Wise', slug: 'wise-va' },
			{ name: 'Woodstock', slug: 'woodstock-va' },
			{ name: 'Wytheville', slug: 'wytheville-va' },
		],
		stateName: 'Virginia',
	},
	WA: {
		cities: [
			{ name: 'Aberdeen', slug: 'aberdeen-wa' },
			{ name: 'Arlington', slug: 'arlington-wa' },
			{ name: 'Auburn', slug: 'auburn-wa' },
			{ name: 'Bainbridge Island', slug: 'bainbridge-island-wa' },
			{ name: 'Bellevue', slug: 'bellevue-wa' },
			{ name: 'Bellingham', slug: 'bellingham-wa' },
			{ name: 'Bonney Lake', slug: 'bonney-lake-wa' },
			{ name: 'Bothell', slug: 'bothell-wa' },
			{ name: 'Bremerton', slug: 'bremerton-wa' },
			{ name: 'Camas', slug: 'camas-wa' },
			{ name: 'Edmonds', slug: 'edmonds-wa' },
			{ name: 'Ellensburg', slug: 'ellensburg-wa' },
			{ name: 'Enumclaw', slug: 'enumclaw-wa' },
			{ name: 'Everett', slug: 'everett-wa' },
			{ name: 'Federal Way', slug: 'federal-way-wa' },
			{ name: 'Gig Harbor', slug: 'gig-harbor-wa' },
			{ name: 'Graham', slug: 'graham-wa' },
			{ name: 'Issaquah', slug: 'issaquah-wa' },
			{ name: 'Kenmore', slug: 'kenmore-wa' },
			{ name: 'Kennewick', slug: 'kennewick-wa' },
			{ name: 'Kent', slug: 'kent-wa' },
			{ name: 'Kirkland', slug: 'kirkland-wa' },
			{ name: 'Klickitat', slug: 'klickitat-wa' },
			{ name: 'Lacey', slug: 'lacey-wa' },
			{ name: 'Lake Stevens', slug: 'lake-stevens-wa' },
			{ name: 'Lakewood', slug: 'lakewood-wa' },
			{ name: 'Longview', slug: 'longview-wa' },
			{ name: 'Lynnwood', slug: 'lynnwood-wa' },
			{ name: 'Maple Valley', slug: 'maple-valley-wa' },
			{ name: 'Marysville', slug: 'marysville-wa' },
			{ name: 'Mercer Island', slug: 'mercer-island-wa' },
			{ name: 'Mill Creek', slug: 'mill-creek-wa' },
			{ name: 'Moses Lake', slug: 'moses-lake-wa' },
			{ name: 'Mount Vernon', slug: 'mount-vernon-wa' },
			{ name: 'Mukilteo', slug: 'mukilteo-wa' },
			{ name: 'Oak Harbor', slug: 'oak-harbor-wa' },
			{ name: 'Olympia', slug: 'olympia-wa' },
			{ name: 'Pasco', slug: 'pasco-wa' },
			{ name: 'Port Angeles', slug: 'port-angeles-wa' },
			{ name: 'Port Orchard', slug: 'port-orchard-wa' },
			{ name: 'Poulsbo', slug: 'poulsbo-wa' },
			{ name: 'Pullman', slug: 'pullman-wa' },
			{ name: 'Puyallup', slug: 'puyallup-wa' },
			{ name: 'Redmond', slug: 'redmond-wa' },
			{ name: 'Renton', slug: 'renton-wa' },
			{ name: 'Richland', slug: 'richland-wa' },
			{ name: 'Sammamish', slug: 'sammamish-wa' },
			{ name: 'Seattle', slug: 'seattle-wa' },
			{ name: 'Snohomish', slug: 'snohomish-wa' },
			{ name: 'Spanaway', slug: 'spanaway-wa' },
			{ name: 'Spokane', slug: 'spokane-wa' },
			{ name: 'Tacoma', slug: 'tacoma-wa' },
			{ name: 'Tumwater', slug: 'tumwater-wa' },
			{ name: 'University Place', slug: 'university-place-wa' },
			{ name: 'Vancouver', slug: 'vancouver-wa' },
			{ name: 'Walla Walla', slug: 'walla-walla-wa' },
			{ name: 'Wenatchee', slug: 'wenatchee-wa' },
			{ name: 'Yakima', slug: 'yakima-wa' },
		],
		stateName: 'Washington',
	},
	WV: {
		cities: [
			{ name: 'Alderson', slug: 'alderson-wv' },
			{ name: 'Barboursville', slug: 'barboursville-wv' },
			{ name: 'Beckley', slug: 'beckley-wv' },
			{ name: 'Bluefield', slug: 'bluefield-wv' },
			{ name: 'Bridgeport', slug: 'bridgeport-wv' },
			{ name: 'Buckhannon', slug: 'buckhannon-wv' },
			{ name: 'Charles Town', slug: 'charles-town-wv' },
			{ name: 'Charleston', slug: 'charleston-wv' },
			{ name: 'Clarksburg', slug: 'clarksburg-wv' },
			{ name: 'Dunbar', slug: 'dunbar-wv' },
			{ name: 'Elkins', slug: 'elkins-wv' },
			{ name: 'Fairmont', slug: 'fairmont-wv' },
			{ name: 'Fayetteville', slug: 'fayetteville-wv' },
			{ name: 'Follansbee', slug: 'follansbee-wv' },
			{ name: 'Glenville', slug: 'glenville-wv' },
			{ name: 'Grafton', slug: 'grafton-wv' },
			{ name: 'Granville', slug: 'granville-wv' },
			{ name: 'Huntington', slug: 'huntington-wv' },
			{ name: 'Hurricane', slug: 'hurricane-wv' },
			{ name: 'Kenova', slug: 'kenova-wv' },
			{ name: 'Keyser', slug: 'keyser-wv' },
			{ name: 'Kingwood', slug: 'kingwood-wv' },
			{ name: 'Lewisburg', slug: 'lewisburg-wv' },
			{ name: 'Logan', slug: 'logan-wv' },
			{ name: 'Madison', slug: 'madison-wv' },
			{ name: 'Martinsburg', slug: 'martinsburg-wv' },
			{ name: 'Milton', slug: 'milton-wv' },
			{ name: 'Montgomery', slug: 'montgomery-wv' },
			{ name: 'Moorefield', slug: 'moorefield-wv' },
			{ name: 'Morgantown', slug: 'morgantown-wv' },
			{ name: 'Moundsville', slug: 'moundsville-wv' },
			{ name: 'New Martinsville', slug: 'new-martinsville-wv' },
			{ name: 'Nitro', slug: 'nitro-wv' },
			{ name: 'Oak Hill', slug: 'oak-hill-wv' },
			{ name: 'Parkersburg', slug: 'parkersburg-wv' },
			{ name: 'Petersburg', slug: 'petersburg-wv' },
			{ name: 'Philippi', slug: 'philippi-wv' },
			{ name: 'Point Pleasant', slug: 'point-pleasant-wv' },
			{ name: 'Prince', slug: 'prince-wv' },
			{ name: 'Princeton', slug: 'princeton-wv' },
			{ name: 'Raleigh', slug: 'raleigh-wv' },
			{ name: 'Ranson', slug: 'ranson-wv' },
			{ name: 'Ravenswood', slug: 'ravenswood-wv' },
			{ name: 'Ripley', slug: 'ripley-wv' },
			{ name: 'Saint Albans', slug: 'saint-albans-wv' },
			{ name: 'Shepherdstown', slug: 'shepherdstown-wv' },
			{ name: 'South Charleston', slug: 'south-charleston-wv' },
			{ name: 'Spencer', slug: 'spencer-wv' },
			{ name: 'Summersville', slug: 'summersville-wv' },
			{ name: 'Vienna', slug: 'vienna-wv' },
			{ name: 'Weirton', slug: 'weirton-wv' },
			{ name: 'Wellsburg', slug: 'wellsburg-wv' },
			{ name: 'Weston', slug: 'weston-wv' },
			{ name: 'Wheeling', slug: 'wheeling-wv' },
			{ name: 'White Sulphur Springs', slug: 'white-sulphur-springs-wv' },
			{ name: 'Williamson', slug: 'williamson-wv' },
			{ name: 'Williamstown', slug: 'williamstown-wv' },
			{ name: 'Wyoming', slug: 'wyoming-wv' },
		],
		stateName: 'West Virginia',
	},
	WI: {
		cities: [
			{ name: 'Appleton', slug: 'appleton-wi' },
			{ name: 'Beaver Dam', slug: 'beaver-dam-wi' },
			{ name: 'Beloit', slug: 'beloit-wi' },
			{ name: 'Brookfield', slug: 'brookfield-wi' },
			{ name: 'Burlington', slug: 'burlington-wi' },
			{ name: 'Caledonia', slug: 'caledonia-wi' },
			{ name: 'Cedarburg', slug: 'cedarburg-wi' },
			{ name: 'Cudahy', slug: 'cudahy-wi' },
			{ name: 'De Pere', slug: 'de-pere-wi' },
			{ name: 'Eau Claire', slug: 'eau-claire-wi' },
			{ name: 'Elkhorn', slug: 'elkhorn-wi' },
			{ name: 'Fond du Lac', slug: 'fond-du-lac-wi' },
			{ name: 'Franklin', slug: 'franklin-wi' },
			{ name: 'Germantown', slug: 'germantown-wi' },
			{ name: 'Green Bay', slug: 'green-bay-wi' },
			{ name: 'Greenfield', slug: 'greenfield-wi' },
			{ name: 'Hartland', slug: 'hartland-wi' },
			{ name: 'Hudson', slug: 'hudson-wi' },
			{ name: 'Janesville', slug: 'janesville-wi' },
			{ name: 'Kaukauna', slug: 'kaukauna-wi' },
			{ name: 'Kenosha', slug: 'kenosha-wi' },
			{ name: 'La Crosse', slug: 'la-crosse-wi' },
			{ name: 'Lake Geneva', slug: 'lake-geneva-wi' },
			{ name: 'Madison', slug: 'madison-wi' },
			{ name: 'Manitowoc', slug: 'manitowoc-wi' },
			{ name: 'Marshfield', slug: 'marshfield-wi' },
			{ name: 'Menomonee Falls', slug: 'menomonee-falls-wi' },
			{ name: 'Mequon', slug: 'mequon-wi' },
			{ name: 'Middleton', slug: 'middleton-wi' },
			{ name: 'Milwaukee', slug: 'milwaukee-wi' },
			{ name: 'Mukwonago', slug: 'mukwonago-wi' },
			{ name: 'Muskego', slug: 'muskego-wi' },
			{ name: 'Neenah', slug: 'neenah-wi' },
			{ name: 'New Berlin', slug: 'new-berlin-wi' },
			{ name: 'Oak Creek', slug: 'oak-creek-wi' },
			{ name: 'Oconomowoc', slug: 'oconomowoc-wi' },
			{ name: 'Onalaska', slug: 'onalaska-wi' },
			{ name: 'Oshkosh', slug: 'oshkosh-wi' },
			{ name: 'Platteville', slug: 'platteville-wi' },
			{ name: 'Pleasant Prairie', slug: 'pleasant-prairie-wi' },
			{ name: 'Plover', slug: 'plover-wi' },
			{ name: 'Racine', slug: 'racine-wi' },
			{ name: 'Sheboygan', slug: 'sheboygan-wi' },
			{ name: 'South Milwaukee', slug: 'south-milwaukee-wi' },
			{ name: 'Stevens Point', slug: 'stevens-point-wi' },
			{ name: 'Stoughton', slug: 'stoughton-wi' },
			{ name: 'Sun Prairie', slug: 'sun-prairie-wi' },
			{ name: 'Superior', slug: 'superior-wi' },
			{ name: 'Verona', slug: 'verona-wi' },
			{ name: 'Watertown', slug: 'watertown-wi' },
			{ name: 'Waukesha', slug: 'waukesha-wi' },
			{ name: 'Waunakee', slug: 'waunakee-wi' },
			{ name: 'Wausau', slug: 'wausau-wi' },
			{ name: 'Wauwatosa', slug: 'wauwatosa-wi' },
			{ name: 'West Allis', slug: 'west-allis-wi' },
			{ name: 'West Bend', slug: 'west-bend-wi' },
			{ name: 'Winnebago', slug: 'winnebago-wi' },
			{ name: 'Wisconsin Rapids', slug: 'wisconsin-rapids-wi' },
		],
		stateName: 'Wisconsin',
	},
	WY: {
		cities: [
			{ name: 'Afton', slug: 'afton-wy' },
			{ name: 'Alpine', slug: 'alpine-wy' },
			{ name: 'Basin', slug: 'basin-wy' },
			{ name: 'Big Horn', slug: 'big-horn-wy' },
			{ name: 'Big Piney', slug: 'big-piney-wy' },
			{ name: 'Buffalo', slug: 'buffalo-wy' },
			{ name: 'Byron', slug: 'byron-wy' },
			{ name: 'Casper', slug: 'casper-wy' },
			{ name: 'Cheyenne', slug: 'cheyenne-wy' },
			{ name: 'Cody', slug: 'cody-wy' },
			{ name: 'Cokeville', slug: 'cokeville-wy' },
			{ name: 'Cowley', slug: 'cowley-wy' },
			{ name: 'Dayton', slug: 'dayton-wy' },
			{ name: 'Diamondville', slug: 'diamondville-wy' },
			{ name: 'Douglas', slug: 'douglas-wy' },
			{ name: 'Dubois', slug: 'dubois-wy' },
			{ name: 'Encampment', slug: 'encampment-wy' },
			{ name: 'Evanston', slug: 'evanston-wy' },
			{ name: 'Evansville', slug: 'evansville-wy' },
			{ name: 'Gillette', slug: 'gillette-wy' },
			{ name: 'Glenrock', slug: 'glenrock-wy' },
			{ name: 'Green River', slug: 'green-river-wy' },
			{ name: 'Greybull', slug: 'greybull-wy' },
			{ name: 'Guernsey', slug: 'guernsey-wy' },
			{ name: 'Hanna', slug: 'hanna-wy' },
			{ name: 'Hudson', slug: 'hudson-wy' },
			{ name: 'Jackson', slug: 'jackson-wy' },
			{ name: 'Kemmerer', slug: 'kemmerer-wy' },
			{ name: 'La Barge', slug: 'la-barge-wy' },
			{ name: 'Lander', slug: 'lander-wy' },
			{ name: 'Laramie', slug: 'laramie-wy' },
			{ name: 'Lingle', slug: 'lingle-wy' },
			{ name: 'Lovell', slug: 'lovell-wy' },
			{ name: 'Lusk', slug: 'lusk-wy' },
			{ name: 'Lyman', slug: 'lyman-wy' },
			{ name: 'Moorcroft', slug: 'moorcroft-wy' },
			{ name: 'Mountain View', slug: 'mountain-view-wy' },
			{ name: 'Natrona', slug: 'natrona-wy' },
			{ name: 'Newcastle', slug: 'newcastle-wy' },
			{ name: 'Pine Bluffs', slug: 'pine-bluffs-wy' },
			{ name: 'Pinedale', slug: 'pinedale-wy' },
			{ name: 'Powell', slug: 'powell-wy' },
			{ name: 'Ranchester', slug: 'ranchester-wy' },
			{ name: 'Rawlins', slug: 'rawlins-wy' },
			{ name: 'Riverton', slug: 'riverton-wy' },
			{ name: 'Rock Springs', slug: 'rock-springs-wy' },
			{ name: 'Saratoga', slug: 'saratoga-wy' },
			{ name: 'Sheridan', slug: 'sheridan-wy' },
			{ name: 'Shoshoni', slug: 'shoshoni-wy' },
			{ name: 'Sundance', slug: 'sundance-wy' },
			{ name: 'Thermopolis', slug: 'thermopolis-wy' },
			{ name: 'Torrington', slug: 'torrington-wy' },
			{ name: 'Upton', slug: 'upton-wy' },
			{ name: 'Wamsutter', slug: 'wamsutter-wy' },
			{ name: 'Weston', slug: 'weston-wy' },
			{ name: 'Wheatland', slug: 'wheatland-wy' },
			{ name: 'Worland', slug: 'worland-wy' },
			{ name: 'Wright', slug: 'wright-wy' },
		],
		stateName: 'Wyoming',
	},
};

export const stateSlugsRegex =
	'alabama|alaska|arizona|arkansas|california|colorado|connecticut|delaware|district-of-columbia|florida|georgia|hawaii|idaho|illinois|indiana|iowa|kansas|kentucky|louisiana|maine|maryland|massachusetts|michigan|minnesota|mississippi|missouri|montana|nebraska|nevada|new-hampshire|new-jersey|new-mexico|new-york|north-carolina|north-dakota|ohio|oklahoma|oregon|pennsylvania|rhode-island|south-carolina|south-dakota|tennessee|texas|utah|vermont|virginia|washington|west-virginia|wisconsin|wyoming';
